import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FleetsInfoResponse } from "types/Fleet.types";
import axios from "config/api";
import { fleets } from "constants/api/fleets";
import { ERROR_MESSAGE } from "constants/api/common";
import { FleetState } from "types/ReduxState/FleetState";

const initialState: FleetState = {
  status: "idle",
  data: null,
  error: null,
};

export const fetchFleets = createAsyncThunk("fetchFleets", async (_, thunkAPI) => {
  try {
    const res = await axios.get<FleetsInfoResponse>(fleets.info);
    const data = { fleets: res.data.fleets.map((e) => ({ ...e, vessels: e.vessels ?? [] })) } as FleetsInfoResponse;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

const fleetSlice = createSlice({
  name: "fleet",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFleets.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchFleets.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchFleets.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
      state.data = null;
    });
  },
});

export default fleetSlice.reducer;
