import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Points } from "types/AIS.types";

interface BoundingRect {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

interface GlobalDBTooltipReferenceState {
  boundingRect: BoundingRect | null;
  vesselLocations: Points[] | null;
}

const initialState: GlobalDBTooltipReferenceState = {
  boundingRect: null,
  vesselLocations: null,
};

export const VesselTooltipReferenceSlice = createSlice({
  name: "GlobalDBClusterTooltip",
  initialState,
  reducers: {
    setTooltipReferenceElement(state, action: PayloadAction<{ boundingRect: BoundingRect | null; vesselLocations: Points[] | null }>) {
      state.boundingRect = action.payload.boundingRect;
      state.vesselLocations = action.payload.vesselLocations;
    },
  },
});

export const { setTooltipReferenceElement } = VesselTooltipReferenceSlice.actions;

export default VesselTooltipReferenceSlice.reducer;
