const Logo = () => {
  return (
    <svg width="109.53" height="30" overflow="visible" viewBox="0 0 504.2 138.1">
      <path
        fill="#00B4DE"
        d="M157.4 114.6c22.4 0 36.9-11.1 
          36.9-28.3 0-21.4-16.3-26.7-30.7-31.4-10.1-3.3-18.9-6.2-18.9-14.2
          0-6 4.8-9.6 12.9-9.6 8.2 0 13.6 4.1 16 12.1l19-7.3c-3.9-13.4-16.9-21.7-34-21.7-21 0-34.1
          10.8-34.1 28.2 0 19.6 15.8 24.8 29.7 29.3 10.6 3.5 19.7 6.5 19.7 15.2 0 6.6-5.4 10.6-14.5
          10.6-10.2 0-17.5-5.3-20.1-14.5l-18.9 7.3c4.9 15.4 18.3 24.3 37 24.3zM238.7 55.4c9.1 0
          15.1 6 15.1 15.2v42.3h19.9V66.5c0-17.3-11.5-28.9-28.6-28.9-8.1 0-15.6 2.7-21
          7.5l-.7.6v-35h-20v102.2h20V69.4c.1-7.9 6.8-14 15.3-14z"
      ></path>
      <g fill="#00B4DE">
        <path d="M284.7 39.5H304.7V112.9H284.7z"></path>
        <path d="M294.8 4.1c-7 0-12.3 5.1-12.3 11.9 0 6.8 5.3 11.9 12.3 11.9 6.9 0 12.1-5.1 12.1-11.9.1-6.8-5.2-11.9-12.1-11.9z"></path>
      </g>
      <path
        fill="#00B4DE"
        d="M357.4 114.7c20.8 0 36.5-16.5 36.5-38.5 0-22.3-15.1-38.5-35.9-38.5-9.4 0-17.4 
          3.4-23.1 9.7l-.7.8v-8.8h-18.5V138h20v-32l.7.7c5.5 5.4 12.5 8 21
          8zm-22.5-38.4c0-12.4 8.1-21 19.7-21 11.3 0 19.4 8.8 19.4 21 0
          12.1-8.2 20.8-19.4 20.8-11.8 0-19.7-8.4-19.7-20.8z"
      ></path>
      <path fill="#00B4DE" d="M402.9 15.8H423.09999999999997V112.89999999999999H402.9z"></path>
      <path
        fill="#00B4DE"
        d="M469.3 55.4c9.1 0 15.1 6 15.1 
          15.2v42.3h19.9V66.5c0-17.3-11.5-28.9-28.6-28.9-8.9 0-16.7
          3.1-22.1 8.6l-.7.7v-7.5H434v73.4h20V69.4c.1-7.9 6.8-14 15.3-14z"
      ></path>
      <g>
        <g fill="#00B4DE">
          <path d="M73.4 23.3V10.1S54.5 0 48 0 22.6 10.1 22.6 10.1v13.2S43.8 12.6 48 12.6c4.1 0 25.4 10.7 25.4 10.7z"></path>
          <path
            d="M95.9 54.3v-7.5S57.2 22.1 48 22.1C38.7 22 0 
            46.8 0 46.8v7.5c0 2.3.1 4.6.3 6.8C12.9
            49.5 29.6 42.5 48 42.5c18.4 0 35.1 7.1 47.7
            18.6.1-2.2.2-4.5.2-6.8zm-1.2-.9zm-1.2-.9l-.1-.1c0 .1
            0 .1.1.1zm-1.4-.8s-.1 0-.1-.1l.1.1zM1.2 53.4zm2.7-1.8s-.1
            0-.1.1c0-.1.1-.1.1-.1zm-1.3.9l-.1.1c0-.1 0-.1.1-.1z"
          ></path>
          <path
            d="M47.9 52.1c-18.2 0-34.5 8-45.7 20.8 1.9 7.2 4.8 
            13.3 8.3 18.5C18 78.5 32 69.8 47.9 69.8c16 0 29.9 8.7 37.4 21.7 3.5-5.2 6.4-11.4 8.3-18.5-11.1-12.8-27.5-20.9-45.7-20.9z"
          ></path>
          <path
            d="M47.9 79.4c-13.8 0-25.6 8.4-30.7 20.3 4.9 5.1 10.2 9 15 
            11.9.6-8.2 7.4-14.6 15.7-14.6 8.3 0 15.1 6.4 15.7 14.6 4.8-2.9 10.1-6.8 15-11.9-5-11.9-16.9-20.3-30.7-20.3z"
          ></path>
          <path d="M48.4 106.8H48c-3.4 0-6.1 2.7-6.1 6.1 0 3.4 2.7 6.1 6.1 6.1 3.4 0 6.1-2.7 6.1-6.1 0-3.3-2.5-5.9-5.7-6.1z"></path>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
