import { parseDateRange } from "components/Filters/DateRangePicker";
import { useAppSelector } from "./reduxHooks";

/**
 * Hook that returns current start / end date and
 * helper variables and functions.
 */
function useDateRange() {
  const range = useAppSelector((state) => state.tableFilterBar.dateRange);

  return {
    range,
    ...parseDateRange(range.start, range.end, range.live),
  };
}

export default useDateRange;
