/* eslint-disable max-lines */
import { Button, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { rem, color, Transition } from "config/variable.styles";
import styled from "styled-components";

export const NotificationContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  .spinner {
    height: ${rem(600)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .small-spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: ${rem(21)};
    font-weight: 400;
    margin: 0 0 ${rem(14)};
    color: ${color.Primary};
  }
  .tab-head {
    padding: ${rem(20)} ${rem(20)} 0;
  }
  .tab-content {
    height: calc(100% - ${rem(85)});
    flex: 1 1 auto;
    overflow: auto;
    padding: ${rem(20)};
    .day {
      font-size: ${rem(12)};
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: ${rem(0.33)};
      color: ${color.DarkRoyalBlue};
      text-transform: uppercase;
      margin: 0 0 ${rem(20)};
    }
    .alertToday {
      margin: 0 0 ${rem(67)};
    }
  }
`;

export const CommentsCardContainer = styled.div`
  display: flex;
  transition: ${Transition};
  position: relative;
  margin-bottom: ${rem(20)};
  padding-bottom: ${rem(10)};
  .activity-time {
    cursor: pointer;
  }
  .comment-text {
    word-break: break-all;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
    .activity-time {
      text-decoration: underline;
    }
    .comment-text {
      cursor: pointer;
    }
  }
  &:after {
    content: "";
    transition: ${Transition};
    position: absolute;
    top: ${rem(-10)};
    left: ${rem(-10)};
    height: calc(100% + ${rem(20)});
    width: calc(100% + ${rem(20)});
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    z-index: 1;
    opacity: 0;
  }
  .userThumb {
    position: relative;
    z-index: 10;
    height: ${rem(32)};
    width: ${rem(32)};
    max-width: ${rem(32)};
    flex: 0 0 ${rem(32)};
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .CommentsContent {
    position: relative;
    z-index: 10;
    padding-left: ${rem(12)};
    h3 {
      font-size: ${rem(14)};
      line-height: ${rem(17)};
      font-weight: 700;
      color: ${color.DarkBlack};
      margin: 0 0 ${rem(4)};
    }
    p {
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      font-weight: 400;
      color: ${color.DarkBlack};
      margin: 0;
      strong {
        margin-left: ${rem(5)};
      }
    }
    .comment-type {
      margin-top: ${rem(12)};
      display: flex;
      p {
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        color: ${color.DarkBlack};
      }
      .type-id {
        padding: 0 ${rem(10)};
      }
      .type-details {
        margin-left: ${rem(10)};
        .info {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: ${rem(450)};
        }
      }
    }
    .comment-action {
      margin-top: ${rem(5)};
      opacity: 0;
      visibility: hidden;
    }
    .visible {
      opacity: 1;
      visibility: visible;
    }
  }
  &:hover {
    .comment-action {
      opacity: 1;
      visibility: visible;
    }
  }
`;
interface AlertCardProp {
  warning?: boolean;
}
export const AlertCardContainer = styled.div<AlertCardProp>`
  transition: ${Transition};
  position: relative;
  margin-bottom: ${rem(20)};
  padding-left: ${rem(20)};
  border-left: ${rem(2)} solid ${color.TurquoiseBlueThree};
  border-color: ${(props) => props.warning && color.coral};
  h3 {
    font-size: ${rem(14)};
    line-height: ${rem(17)};
    font-weight: 700;
    color: ${color.DarkBlack};
    margin: 0 0 ${rem(4)};
  }
  p {
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-weight: 400;
    color: ${color.DarkBlack};
    margin: 0;
  }
  .alert-info {
    margin-top: ${rem(10)};
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
`;
interface Props {
  read?: boolean;
}
export const ReadDot = styled.div<Props>`
  position: absolute;
  z-index: 10;
  top: ${rem(6)};
  right: 0;
  height: ${rem(10)};
  width: ${rem(10)};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) => (props.read ? color.lightBlueGrey : color.Primary)};
`;
export const NotificationTab = withStyles(
  {
    root: {
      padding: 0,
      minHeight: "inherit",
      "& .MuiTabs-flexContainer": {
        borderBottom: `${rem(4)} solid ${color.CloudyBlue}`,
      },
      "& .MuiButtonBase-root": {
        minWidth: "inherit",
        padding: `${rem(5)} 0`,
        overflow: "visible",
        lineHeight: "1",
        minHeight: "inherit",
        fontSize: rem(12),
        letterSpacing: rem(0.33),
        fontWeight: 400,
        color: color.steel,
        opacity: 1,
        marginRight: rem(10),
        position: "relative",
        textTransform: "inherit",
        "&:after": {
          content: '""',
          position: "absolute",
          bottom: rem(-4),
          left: 0,
          height: rem(4),
          opacity: 0,
          background: color.DarkRoyalBlue,
          width: "100%",
        },
        "&.Mui-selected": {
          color: color.DarkRoyalBlue,
          fontWeight: 700,
          "&:after": {
            opacity: 1,
          },
        },
      },
    },
    indicator: {
      display: "none",
    },
  },
  { name: "notification-tab" }
)(Tabs);

export const CommentAction = withStyles(
  {
    root: {
      height: "auto",
      width: "auto",
      minWidth: "auto",
      padding: 0,
      margin: `0 ${rem(10)} 0 0`,
      background: "transparent",
      fontSize: rem(12),
      lineHeight: rem(15),
      fontWeight: "normal",
      textTransform: "inherit",
      color: color.TurquoiseBlueThree,
      "&:hover": {
        background: "transparent",
        "& svg path": {
          fill: `${color.Primary}`,
        },
        "& svg g": {
          fill: `${color.Primary}`,
        },
      },
    },
  },
  { name: "comment-action" }
)(Button);

export const AddBtn = withStyles(
  {
    root: {
      height: rem(32),
      minWidth: rem(70),
      backgroundImage: `linear-gradient(to right, ${color.coolBlue}, ${color.Primary} 73%)`,
      borderRadius: rem(25),
      fontSize: rem(14),
      fontWeight: 500,
      boxShadow: "inherit",
      padding: `0 ${rem(10)}`,
      color: color.White,
      "&:hover": {
        boxShadow: "inherit",
      },
      "&.Mui-disabled": {
        opacity: "0.5",
        color: color.White,
      },
    },
    label: {
      textTransform: "initial",
    },
  },
  { name: "add-comment-btn" }
)(Button);

export const InlineReplyContainer = styled.div`
  display: flex;
  width: ${rem(400)};
  padding: 10px 0;
  align-items: center;
  .MuiCircularProgress-colorPrimary {
    color: ${color.White};
    margin-left: ${rem(8)};
  }
  .MuiInputBase-root {
    width: 100%;
    display: block;
    margin: 0;
    border: 1px solid lightgray;
    &:after,
    &:before {
      display: none;
    }
    .MuiInputBase-input {
      background-color: ${color.White};
      height: ${rem(40)};
      box-sizing: border-box;
      padding: ${rem(6)} ${rem(20)};
      border: ${rem(1)} solid ${color.White};
      font-size: ${rem(14)};
      color: ${color.DarkBlack};
      line-height: ${rem(28)};
    }
  }
`;

export const NoNotificationPlaceholder = styled.div`
  padding: 16px;
  .text {
    display: block;
    width: 354px;
    height: 32px;
    font-size: 13px;
    font-weight: 900;
    margin-top: 10px;
    text-transform: uppercase;
  }
`;

interface SeverityIndicatorProps {
  backgroundColor: "#f05e5c" | "#ffbc57" | "#3cdaac" | "#828382";
}
export const SeverityIndicator = styled.div<SeverityIndicatorProps>`
  width: ${rem(16)};
  flex: 0 0 ${rem(16)};
  max-width: ${rem(16)};
  width: ${rem(16)};
  height: ${rem(16)};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  margin-top: ${rem(3)};
`;
