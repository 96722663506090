import { CircularProgress, TextField } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Dispatch, SetStateAction, useState } from "react";
import { addNewComment } from "services/redux/reducers/Comments/comments.actions";
import { AddBtn, InlineReplyContainer } from "../NotificationContent.styles";

interface InlineReplyProps {
  activityID: string;
  commentID: string;
  onClose: () => void;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}
const InlineReply = (props: InlineReplyProps) => {
  const { onClose, activityID, commentID, setEditMode } = props;
  const [text, setText] = useState("");
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.comments.postComment);

  const handleAddReply = () => {
    if (!text) return;
    dispatch(addNewComment({ activityID, content: { text }, reply_to: commentID }))
      .then(() => {
        setEditMode(false);
        setText("");
      })
      .catch((e) => console.log(e));
  };

  return (
    <InlineReplyContainer>
      <TextField autoFocus value={text} onChange={(e) => setText(e.target.value)} placeholder="Add Reply" />
      <AddBtn disabled={!text} onClick={handleAddReply} style={{ marginLeft: "10px" }}>
        Reply {status === "loading" && <CircularProgress size={18} />}
      </AddBtn>
      <AddBtn onClick={onClose} style={{ marginLeft: "10px" }}>
        Cancel
      </AddBtn>
    </InlineReplyContainer>
  );
};

export default InlineReply;
