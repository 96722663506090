import { IFilterItem } from "components/Filters/FilterBar/FilterBar.types";

type Options = {
  order: "asc" | "desc";
};
export function sortFilterItem(arr?: IFilterItem[], options: Options = { order: "asc" }) {
  if (!arr) return [];
  return arr.slice().sort((a, b) => {
    const comparator = a.name?.localeCompare(b?.name);
    if (options.order === "asc") {
      return comparator;
    }
    return -comparator;
  });
}
