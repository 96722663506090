import axios from "axios";
import routes from "./routes";
import history from "./history";
import { toast } from "react-toastify";
import { ERROR_MESSAGE } from "../constants/api/common";
import { ErrorToast } from "../components/Toastbar";

declare module "axios" {
  export interface AxiosRequestConfig {
    disableErrorToast?: boolean;
  }
}

const axiosInstance = axios.create({
  baseURL: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    if (config.url !== routes.login.path) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      } else {
        const route = Object.values(routes).find((item) => item.path === history.location.pathname);
        if (route && route.isPrivate) {
          history.push(routes.login.path);
        }
      }
    }
    return config;
  },
  function (error) {
    toast.error(<ErrorToast>{error.message || ERROR_MESSAGE}</ErrorToast>);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (isCancel(error)) return;
    if (error.response?.status === 401 || error.response?.status === 422) {
      if (history.location.pathname.includes("/reset-password/")) {
        history.push(routes.requestPassword.path);
        if (!error.response.config.disableErrorToast) {
          toast.error(<ErrorToast>{error.response?.data ? JSON.stringify(error.response.data) : ERROR_MESSAGE}</ErrorToast>);
        }
      } else {
        localStorage.clear();
        history.push(routes.login.path);
      }
    } else {
      if (!error.response?.config.disableErrorToast) {
        toast.error(<ErrorToast>{JSON.stringify(error.response?.data) ?? ERROR_MESSAGE}</ErrorToast>);
      }
    }
    return Promise.reject(error);
  }
);

export const isCancel = axios.isCancel;
export const cancelToken = axios.CancelToken;
export default axiosInstance;
