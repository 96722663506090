const Calendar = () => {
  return (
    <span className="icon icon-plus-calendar">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18">
        <path
          fill="#000"
          fillRule="evenodd"
          d="M16.174 7.2H1.797V4.474c0-.483.391-.874.873-.874h.924v1.8h1.797V3.6h7.189v1.8h1.797V3.6h.899c.496 
          0 .898.403.898.9v2.7zm0 8.1c0 .495-.404.9-.898.9H2.696a.9.9 0
          01-.899-.9V9h14.377v6.3zm-16.116.685C.058 16.979.919 18 1.91
          18h14.377c.993 0 1.712-.919 1.712-2.015 0-.35-.029-11.161-.029-11.748
          0-1.874-.252-2.437-3.594-2.437V0H12.58v1.8H5.39V0H3.594v1.8H1.797C.81 1.8 0 2.61 0 3.6l.058 12.385z"
        ></path>
      </svg>
    </span>
  );
};

export default Calendar;
