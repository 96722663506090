import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { sensors } from "constants/api/sensors";
import axios from "config/api";
import { ERROR_MESSAGE } from "constants/api/common";
import { ReduxState } from "types/ReduxState/common.types";
import { SensorInfo, SensorQuery, SensorQueryResponse, SensorCameraStatus } from "types/Sensor.types";

const initialState: ReduxState<SensorQueryResponse | null> = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchSensors = createAsyncThunk("fetchSensors", async (payload: SensorQuery, { rejectWithValue }) => {
  try {
    const res = await axios.post<SensorQueryResponse>(sensors.query, payload);
    return res.data;
  } catch (err) {
    return rejectWithValue(ERROR_MESSAGE);
  }
});

const sensorSlice = createSlice({
  name: "sensors",
  initialState: initialState,
  reducers: {
    setStatus(state, action: PayloadAction<{ camera_id: string; status: SensorCameraStatus }>) {
      if (!state.data) return;
      state.data = {
        sensors_query_result: state.data?.sensors_query_result.map((e) => ({
          ...e,
          live_view_status: e.id === action.payload.camera_id ? action.payload.status : e.live_view_status,
        })),
      };
    },
    setSensors(state, action: PayloadAction<SensorInfo[]>) {
      if (!state.data) return;
      state.data.sensors_query_result = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSensors.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchSensors.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchSensors.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = JSON.stringify(action.payload);
    });
  },
});

export const { setStatus, setSensors } = sensorSlice.actions;
export default sensorSlice.reducer;
