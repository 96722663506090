import { generatePath, ExtractRouteParams } from "react-router";
import queryString from "query-string";

/**
 * Takes router params and query string as JavaScript objects and
 * returns a URL containing params and query.
 * @param path Route path
 * @param query Query string params as an object
 * @param params React router path params
 * @returns path
 */
export function generatePathWithQuery<T extends string>(path: T, query: Record<string, string | number>, params?: ExtractRouteParams<T>) {
  const routePath = generatePath(path, params);
  const searchParams = !!Object.keys(query).length ? `?${queryString.stringify(query)}` : "";
  return `${routePath}${searchParams}`;
}
