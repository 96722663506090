import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vessel_id: 0,
};

export const setVesselIdSlice = createSlice({
  name: "setVesselId",
  initialState,
  reducers: {
    setVesselId: (state, action) => {
      state.vessel_id = action.payload;
    },
  },
});

export const { setVesselId } = setVesselIdSlice.actions;

export default setVesselIdSlice.reducer;
