export const color = {
  Primary: "#0854a0",
  WarmGrey: "#888888",
  White: "#ffffff",
  Black: "#000000",
  DarkBlack: "#333333",
  BrownishGrey: "#666666",
  CloudyBlue: "#d6dce5",
  Auburn: "#993d00",
  TurquoiseBlue: "#02b4de",
  DarkSkyBlue: "#31c3e6",
  DarkRoyalBlue: "#00016a",
  iceBlue: "#f7fbff",
  lightBlueGrey: "#e6eff9",
  rustyOrange: "#e26614",
  darkIndigo: "#081a2c",
  duckEggBlue: "#f1f6fc",
  paleOrange: "#ffbc57",
  turquoiseBlue: "#00c8c8",
  cocoa: "#65433b",
  iceBlueTwo: "#f9faff",
  grayWhite: "#f1f1f1",
  graywhiteTwo: "#dfdfdf",
  lightBlueGreyTwo: "#c0d0e7",
  paleGreyTwo: "#f7f8fb",
  coolBlue: "#4481be",
  silver: "#d1d7de",
  pinkishGrey: "#cccccc",
  paleGreyThree: "#eef3f9",
  coral: "#f05e5c",
  aquaMarine: "#3cdaac",
  darkBlue: "#030067",
  TurquoiseBlueThree: "#00b4de",
  cloudyBlueTwo: "#b3b2d1",
  warmGreyTwo: "#999999",
  darkBlueGreen: "#003328",
  offWhite: "#ffffec",
  whiteThree: "#f6f6f6",
  aqua: "#22bce1",
  steel: "#8e8e8f",
  lightBlueGreyThree: "#cdd6e7",
  paleGreyFour: "#eff0f5",
  lightPeriwinkle: "#e3e6fc",
  warmBlue: "#4253ce",
  warmGreyThree: "#808080",
  paleGreyFive: "#e6eaf5",
  iceBlueThree: "#fafbff",
  SkyBlue: "#01b2dd",
  brownishGreyTwo: "#656565",
  cloudyBlueThree: "#b1bccc",
  greenColor: "#2fc3a1",
  DarkMintGreen: "#3ddaac",
  WhiteFour: "#f2f2f2",
  purplishBlue: "#6f3aff",
  iceBlueFour: "#fcfdff",
  lightPeriwinkleTwo: "#c4dcfa",
  lighterPurple: "#7f4fff",
  perrywinkle: "#75a2d7",
  lightGray: "#C3C4C5",
  oceanBlue: "#0854a0",
};

export const Transition = "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms";

export const xl = (content: any) => `
  @media (max-width: 1366px) {
    ${content};
  }
`;

export const rem = (pxValue: any) => {
  const ratio = 16; // Set according to the project configuration ratio
  let pxValues: any;
  if (Array.isArray(pxValue)) {
    pxValue = pxValue[0];
  }
  pxValues = parseInt(pxValue);
  return pxValues / ratio + "rem";
};

const hexToRgb = (hex: any) => {
  // http://stackoverflow.com/a/5624139
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m: string, r: string, g: string, b: string) {
    return r + r + g + g + b + b;
  });
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result![1], 16),
    g: parseInt(result![2], 16),
    b: parseInt(result![3], 16),
  };
};

export const rgba = (hex: string, alpha: number) => {
  let rgbColor = hexToRgb(hex);
  return `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b}, ${alpha})`;
};
