import { forwardRef } from "react";
import CommentsCard from "./CommentsCard";
import { RecentComment } from "types/Comments.types";
import useFleets from "hooks/useFleets";

interface CommentSectionProps {
  title: string;
  comments: RecentComment[];
  onNotificationClose: () => void;
}
const CommentSection = (props: CommentSectionProps, ref: any) => {
  const { title, comments, onNotificationClose } = props;
  useFleets();

  if (comments.length === 0) {
    return null;
  }

  return (
    <>
      <p className="day">{title}</p>
      {comments.map((element, index) => {
        // Attaching ref to last element
        if (comments.length === index + 1 && !!ref) {
          return (
            <CommentsCard
              ref={ref}
              key={element.comment.id}
              fullName={element.user_info.full_name ?? element.user_info.username}
              text={element.comment.content.text}
              imageUrl={element.user_info.image_url}
              activityID={element.conversation_info.activity_id}
              commentID={element.comment.id}
              readStatus={element.read_status}
              createdAt={element.comment.created_at}
              startTime={element.activity?.start_dttm}
              endTime={element.activity?.end_dttm}
              severity={element.activity?.severity}
              activityTitle={element.activity?.title}
              vesselID={element.activity?.vessel_id}
              onNotificationClose={onNotificationClose}
            />
          );
        }
        return (
          <CommentsCard
            key={element.comment.id}
            fullName={element.user_info.full_name ?? element.user_info.username}
            text={element.comment.content.text}
            imageUrl={element.user_info.image_url}
            activityID={element.conversation_info.activity_id}
            commentID={element.comment.id}
            readStatus={element.read_status}
            createdAt={element.comment.created_at}
            startTime={element.activity?.start_dttm}
            endTime={element.activity?.end_dttm}
            severity={element.activity?.severity}
            activityTitle={element.activity?.title}
            vesselID={element.activity?.vessel_id}
            onNotificationClose={onNotificationClose}
          />
        );
      })}
    </>
  );
};

export default forwardRef(CommentSection);
