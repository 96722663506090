interface ReplyIconProps {
  size?: number;
  color?: string;
}
const Reply = (props: ReplyIconProps) => {
  const { size = 16, color = "#333" } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 14.667a.5.5 0 0 1-.439-.261l-.014-.025A8.503 8.503 0 0 
        0 7.612 10h-.945v2.833a.502.502 0 0 1-.848.359l-5.667-5.5a.498.498 
        0 0 1 0-.717l5.667-5.5a.5.5 0 0 1 .848.358v2.835C11.829 4.758 16 
        8.984 16 14.167a.5.5 0 0 1-.5.5zM6.167 9h1.445a9.505 9.505 0 0 1 
        7.176 3.274c-.863-3.78-4.251-6.607-8.288-6.607h-.333a.5.5 0 0 
        1-.5-.5V3.015L1.218 7.333l4.449 4.318V9.5a.5.5 0 0 1 .5-.5z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Reply;
