import { createSlice } from "@reduxjs/toolkit";
import { GetCommentsResponse } from "types/Comments.types";
import { UserAPIResponse } from "types/ReduxState/UserState.types";
import { addNewComment, fetchComments, deleteComment, initialState, editComment, setReadStatus } from "./comments.actions";

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    clearComments() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    /**
     * Reducers for addComment async action
     */
    builder.addCase(addNewComment.pending, (state) => {
      state.postComment.status = "loading";
    });
    builder.addCase(addNewComment.fulfilled, (state, action) => {
      switch (action.payload.type) {
        case "reply":
          return {
            ...state,
            postComment: {
              ...state.postComment,
              status: "success",
            },
            commentsList: {
              ...state.commentsList,
              data: {
                activity_id: action.payload.activityID,
                threads:
                  state.commentsList.data?.threads.map((thread) => {
                    if (thread.comment.id === action.payload.reply_to) {
                      return { ...thread, replies: [...thread.replies, { ...action.payload.data }] };
                    }
                    return thread;
                  }) ?? [],
                users: [...(state.commentsList.data?.users ?? [])],
              },
            },
          };
        case "comment":
          const { id, full_name, username, prefix, image_url } = JSON.parse(localStorage.getItem("user")!) as UserAPIResponse;
          // If data already exists than append the new comment
          // Otherwise initialize the the thread
          const updatedCommentsList: GetCommentsResponse =
            state.commentsList.data && state.commentsList.data.threads.length > 0
              ? {
                  ...state.commentsList.data,
                  threads: [...state.commentsList.data.threads, { comment: action.payload.data, replies: [] }],
                }
              : {
                  activity_id: action.payload.activityID,
                  users: [{ id, full_name, username, prefix, image_url, status: "ONLINE", updated_at: new Date().toISOString() }],
                  threads: [{ comment: action.payload.data, replies: [] }],
                };

          return {
            ...state,
            postComment: {
              ...state.postComment,
              status: "success",
            },
            commentsList: {
              ...state.commentsList,
              data: updatedCommentsList,
            },
          };
        default:
          return state;
      }
    });
    builder.addCase(addNewComment.rejected, (state, action) => {
      state.postComment.status = "error";
      state.postComment.error = JSON.stringify(action.payload);
    });

    /**
     * Reducers for fetchComment async action
     */
    builder.addCase(fetchComments.pending, (state) => {
      // Set state to loading only for the first time
      state.commentsList.status = !state.commentsList.data ? "loading" : "refetching";
    });
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.commentsList.data = action.payload;
      state.commentsList.status = "success";
      state.commentsList.error = null;
    });
    builder.addCase(fetchComments.rejected, (state, action) => {
      state.commentsList.data = null;
      state.commentsList.error = JSON.stringify(action.payload);
      state.commentsList.status = "error";
    });

    /**
     * Reducers for deleteComment async action
     */
    builder.addCase(deleteComment.pending, (state) => {
      state.deleteComment.status = "loading";
    });
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      switch (action.payload.type) {
        case "reply":
          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              data: state.commentsList.data
                ? {
                    ...state.commentsList.data,
                    /** Iterate through existing comments to change text */
                    threads: state.commentsList.data.threads.map((t) => {
                      if (t.comment.id === action.payload.parentCommentId) {
                        return {
                          ...t,
                          replies: t.replies.map((r) => {
                            if (r.id === action.payload.data.id) {
                              return { ...r, content: { text: action.payload.data.content.text }, status: action.payload.data.status };
                            }
                            return r;
                          }),
                        };
                      }
                      return t;
                    }),
                  }
                : null,
            },
            deleteComment: {
              ...state.deleteComment,
              status: "success",
            },
          };
        case "comment":
          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              data: state.commentsList.data
                ? {
                    ...state.commentsList.data,
                    /** Iterate through existing comments to change text */
                    threads: state.commentsList.data.threads.map((t) => {
                      if (t.comment.id === action.payload.commentID) {
                        return {
                          ...t,
                          comment: { ...t.comment, content: { text: action.payload.data.content.text }, status: action.payload.data.status },
                        };
                      }
                      return t;
                    }),
                  }
                : null,
            },
            deleteComment: {
              ...state.deleteComment,
              status: "success",
            },
          };
        default:
          return state;
      }
    });
    builder.addCase(deleteComment.rejected, (state, action) => {
      state.deleteComment.status = "error";
      state.deleteComment.data = null;
      state.deleteComment.error = JSON.stringify(action.payload);
    });

    /**
     * Reducers for editComment async action
     */
    builder.addCase(editComment.pending, (state) => {
      state.editComment.status = "loading";
    });
    builder.addCase(editComment.fulfilled, (state, action) => {
      switch (action.payload.type) {
        case "reply":
          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              data: state.commentsList.data
                ? {
                    ...state.commentsList.data,
                    /** Iterate through existing comments to change text */
                    threads: state.commentsList.data.threads.map((t) => {
                      if (t.comment.id === action.payload.parentCommentID) {
                        return {
                          ...t,
                          replies: t.replies.map((r) => {
                            if (r.id === action.payload.data.id) {
                              return { ...r, content: { text: action.payload.data.content.text } };
                            }
                            return r;
                          }),
                        };
                      }
                      return t;
                    }),
                  }
                : null,
            },
            editComment: {
              ...state.editComment,
              status: "success",
            },
          };
        case "comment":
          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              data: state.commentsList.data
                ? {
                    ...state.commentsList.data,
                    /** Iterate through existing comments to change text */
                    threads: state.commentsList.data.threads.map((t) => {
                      if (t.comment.id === action.payload.commentID) {
                        return { ...t, comment: { ...t.comment, content: { text: action.payload.data.content.text } } };
                      }
                      return t;
                    }),
                  }
                : null,
            },
            editComment: {
              ...state.editComment,
              status: "success",
            },
          };
        default:
          return state;
      }
    });
    builder.addCase(editComment.rejected, (state, action) => {
      state.editComment.data = null;
      state.editComment.status = "error";
      state.editComment.error = JSON.stringify(action.payload);
    });
    /**
     * Reducer for setReadStatus async action
     */
    builder.addCase(setReadStatus.fulfilled, (state, action) => {
      switch (action.payload.type) {
        case "reply":
          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              data: state.commentsList.data
                ? {
                    ...state.commentsList.data,
                    threads: state.commentsList.data.threads.map((t) => {
                      if (t.comment.id === action.payload.parentCommentID) {
                        return {
                          ...t,
                          replies: t.replies.map((r) => {
                            if (r.id === action.payload.commentID) {
                              return { ...r, read_status: action.payload.read_status };
                            }
                            return r;
                          }),
                        };
                      }
                      return t;
                    }),
                  }
                : null,
            },
          };
        case "comment":
          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              data: state.commentsList.data
                ? {
                    ...state.commentsList.data,
                    threads: state.commentsList.data.threads.map((t) => {
                      if (t.comment.id === action.payload.commentID) {
                        return { ...t, comment: { ...t.comment, read_status: action.payload.read_status } };
                      }
                      return t;
                    }),
                  }
                : null,
            },
          };
        default:
          return state;
      }
    });
  },
});

export const { clearComments } = commentsSlice.actions;
export default commentsSlice.reducer;
