const AllSeverities = () => {
  return (
    <span className="icon icon-plus-Severities">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 33 32">
        <g fill="none" fillRule="evenodd" transform="translate(.333)">
          <circle cx="16" cy="16" r="16" fill="#EEF3F9"></circle>
          <circle cx="16.047" cy="8.514" r="6" fill="#3CDAAC"></circle>
          <circle cx="22.659" cy="20.142" r="6" fill="#FFBC57"></circle>
          <circle cx="9.326" cy="20.142" r="6" fill="#F05E5C"></circle>
        </g>
      </svg>
    </span>
  );
};

export default AllSeverities;
