import { memo } from "react";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import { useTransfer } from "../../utils";
import { TransferItem } from "components/Transfer/Transfer.types";
import { FlagIcon } from "./TransferTableRow.styles";
import { isPreviewEnabled } from "utils";

const TransferRow = (props: TransferItem) => {
  const { id, name, selected, country_iso } = props;
  const { onChange, label } = useTransfer();
  const flagEnabled = label === "Vessels";
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={selected}
      tabIndex={-1}
      key={id}
      selected={selected}
      onClick={() => onChange?.((values) => values.map((e) => (e.id === id ? { ...e, selected: !selected } : e)))}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={selected} inputProps={{ "aria-labelledby": id }} />
      </TableCell>
      {flagEnabled && (
        <TableCell component="th" id={id} scope="row">
          <FlagIcon>
            {country_iso ? <img src={require(`assets/flags/${country_iso?.toLowerCase()}.svg`).default} alt={name} /> : <div className="gray-bg" />}
          </FlagIcon>
        </TableCell>
      )}
      <TableCell>{name}</TableCell>
      {isPreviewEnabled && <TableCell>Technical Manager</TableCell>}
    </TableRow>
  );
};

export default memo(TransferRow);
