import { AISState } from "types/ReduxState/AISState.types";
import { convertDuration, UTCDate } from "utils";
import { differenceInSeconds } from "date-fns";
import { WeatherField } from "types/Weather.types";

/**
 * Formats latitude and longitude to 2 decimal points and
 * Adds suffix N/S and E/W to latitude and longitude respectively.
 * @param lat Latutude
 * @param lon Longitude
 */
export function formatCoordinates(lat?: number, lon?: number): [string, string] {
  // eslint-disable-next-line eqeqeq
  if (lat == null && lon == null) return ["-", "-"];
  if (lat === 0 && lon === 0) return ["0°", "0°"];

  const latitude = lat ? `${lat?.toFixed(2)}° ${lat === 0 ? "" : lat < 1 ? "S" : "N"}`.trim() : "-";
  const longitude = lon ? `${lon?.toFixed(2)}° ${lon === 0 ? "" : lon < 1 ? "W" : "E"}`.trim() : "-";
  return [latitude, longitude];
}

export function getAISStatus(aisInfo: AISState, vesselID?: string) {
  const aisPoints = aisInfo?.data?.results?.filter((i) => i.vessel_id === vesselID)?.[0]?.points;
  const aisStatus = aisPoints?.[aisPoints.length - 1];
  const [lat, lon] = formatCoordinates(aisStatus?.lat, aisStatus?.lon);
  const lastUpdated = aisStatus?.timestamp && convertDuration(differenceInSeconds(UTCDate(), new Date(aisStatus.timestamp)));
  return { aisStatus: { ...aisStatus, lat, lon }, lastUpdated };
}

/**
 * Format course to always have 3 digits.
 * @param course course field received from API.
 * @returns Formatted 3 digit course field or '-'.
 */
export const formatCourse = (course?: number) => course?.toLocaleString("en", { minimumIntegerDigits: 3 }) ?? "-";

type FormatWeatherOptions = {
  unit?: string;
};
/**
 * Show only one field if both min and max are same
 */
export const formatWeatherField = (entity: WeatherField | undefined, options: FormatWeatherOptions = { unit: "" }) => {
  if (!entity) return "-";
  const { min, max } = entity;
  if (min === max) {
    return `${min} ${options.unit}`.trim();
  }
  return `${min}-${max} ${options.unit}`.trim();
};
