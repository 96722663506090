import { useEffect, useRef, useState } from "react";
import { DateRangePicker as RangePicker, DateRange } from "materialui-daterange-picker";

import { useAppDispatch } from "hooks/reduxHooks";
import { setDateRange } from "services/redux/reducers/TableFilterBar/TableFilterBar.slice";
import { DateRangeRoot, RangeControl } from "./DateRangePicker.styles";
import Calendar from "components/Icon/Calendar";
import useDateRange from "hooks/useDateRange";

// const getDefinedRanges = (): DefinedRange[] => {
//   const today = new Date();
//   const yesterday = subDays(today, 1);
//   const firstDayOfWeek = startOfWeek(today, { weekStartsOn: 1 });
//   const firstDayOfLastWeek = subDays(firstDayOfWeek, 7);
//   const previousMonth = new Date().setMonth(today.getMonth() - 1);

//   return [
//     {
//       label: "Today",
//       startDate: today,
//       endDate: today,
//     },
//     {
//       label: "Yesterday",
//       startDate: yesterday,
//       endDate: yesterday,
//     },
//     {
//       label: "This Week",
//       startDate: firstDayOfWeek,
//       endDate: today,
//     },
//     {
//       label: "Last Week",
//       startDate: firstDayOfLastWeek,
//       endDate: endOfWeek(firstDayOfLastWeek, { weekStartsOn: 1 }),
//     },
//     {
//       label: "Last 7 Days",
//       startDate: subDays(today, 7),
//       endDate: today,
//     },
//     {
//       label: "This Month",
//       startDate: startOfMonth(today),
//       endDate: today,
//     },
//     {
//       label: "Last Month",
//       startDate: startOfMonth(previousMonth),
//       endDate: endOfMonth(previousMonth),
//     },
//   ];
// };

const DateRangePicker = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { startDate, endDate, getText } = useDateRange();

  // Detects if user has clicked outside the container
  // To close the date range picker when user clicks outside.
  // Listener is attached to document only when calendar is opened.
  // closeOnClickOutside prop sometimes doesn't close on outside clicks.
  useEffect(() => {
    if (!open) return;

    function listener(this: Document, e: MouseEvent) {
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }
      setOpen(false);
    }

    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [open]);

  const onChange = ({ startDate, endDate }: DateRange) => {
    if (!startDate || !endDate) return;
    dispatch(setDateRange({ start: startDate.toISOString(), end: endDate.toISOString(), live: false }));
    setOpen(false);
  };

  // DateRangePicker doesn't support controlled way of handling it's state.
  // So, using key prop to make it behave like controlled. key will re-mount
  // The component and it will receive updated values in initialDateRange prop.
  // Otherwise, it would be not be possible to jump to current date on clicking Live button.
  return (
    <DateRangeRoot ref={ref}>
      <RangeControl>
        <Calendar />
        <span className="date-range-text" onClick={() => setOpen(!open)}>
          {getText()}
        </span>
      </RangeControl>
      <RangePicker
        key={startDate.toISOString()}
        open={open}
        onChange={onChange}
        closeOnClickOutside
        maxDate={new Date()}
        toggle={() => setOpen(!open)}
        wrapperClassName="date-picker-popup"
        initialDateRange={{ startDate, endDate }}
      />
    </DateRangeRoot>
  );
};

export { DateRangePicker };
