export const images = {
  shipImage: require("../assets/images/ship-image.png").default,
  timelineShip: require("../assets/images/timeline-ship.svg").default,
  cameraImages: require("../assets/images/camera-images.jpg").default,
  vesselIcon: require("../assets/images/vessel-icon.svg").default,
  vesselIconSimple: require("../assets/images/vessel-icon-simple.svg").default,
  barCircle: require("../assets/images/bar-circle-icon.svg").default,
  barPin: require("../assets/images/bar-pin-icon.svg").default,
  barTriangle: require("../assets/images/bar-triangle-icon.svg").default,
  videoSample: require("../assets/images/video-sample.mp4").default,
  nortAmericaImage: require("../assets/images/login-bg-new.jpeg").default,
  polygonRight: require("../assets/images/polygon-right.svg").default,
  loginBg: require("../assets/images/login-bg.png").default,
  calendarIcon: require("../assets/images/calendar-icon.svg").default,
  profileImage: require("../assets/images/profile-image.jpg").default,
  pageLoader: require("../assets/images/page-loader.gif").default,
  placeholderProfile: require("../assets/images/placeholder-profile.jpg").default,
  imagePlaceholder: require("../assets/images/placeholder-image.jpg").default,
  daigramImage: require("../assets/images/daigram-image.png").default,
  mapPlaceholderImage: require("../assets/images/map-placeholder-image.png").default,
  crossArrowIcon: require("../assets/images/cross-arrow-icon.svg").default,
  downArrowIcon: require("../assets/images/down-arrow-icon.svg").default,
  scrollbarGrip: require("../assets/images/grip.svg").default,
  scrollbarGripHighlighted: require("../assets/images/grip-highlited.svg").default,
  vesselsPlaceholder: require("../assets/images/vessels-placeholder-icon.svg").default,
};

export const vesselsIcon: { [key: string]: string } = {
  TANKER: require("../assets/VesselTypes/tanker.svg").default,
  PASSENGER: require("../assets/VesselTypes/passenger.svg").default,
  SERVICE: require("../assets/VesselTypes/service.svg").default,
  OTHER: require("../assets/VesselTypes/other.svg").default,
  CARGO: require("../assets/VesselTypes/dry-cargo.svg").default,
  ALL: require("../assets/VesselTypes/all.svg").default,
};

export const vesselGhostIcon = {
  alert: require("assets/VesselMarkers/event-location-alert.svg").default,
  attention: require("assets/VesselMarkers/event-location-attention.svg").default,
  routine: require("assets/VesselMarkers/event-location-routine.svg").default,
};
export const vesselDBMarkers = {
  routine: require("assets/VesselMarkers/vessel-icon-routine.svg").default,
  alert: require("assets/VesselMarkers/vessel-icon-alert.svg").default,
  attention: require("assets/VesselMarkers/vessel-icon-attention.svg").default,
  default: require("assets/VesselMarkers/vessel-icon-default.svg").default,
  path: require("assets/images/circle.svg").default,
};

export const globalDBMarkers = {
  routine: require("assets/VesselMarkers/global-db-routine.svg").default,
  alert: require("assets/VesselMarkers/global-db-alert.svg").default,
  attention: require("assets/VesselMarkers/global-db-attention.svg").default,
  default: require("assets/VesselMarkers/global-db-default.svg").default,
};
