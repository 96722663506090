import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SummaryRequestQuery, SummaryByVessel } from "types/ActivityDetails.types";
import axios from "config/api";
import { activity } from "constants/api/activity";
import { format, subDays } from "date-fns";
import { ActivityByVesselState } from "types/ReduxState/ActivityBarState.types";
import { ERROR_MESSAGE } from "constants/api/common";

const initialState: ActivityByVesselState = {
  status: "idle",
  error: null,
  data: null,
  singleVesselSummary: null,
};

export const fetchVesselActivities = createAsyncThunk("vesselActivities", async (requestQuery: SummaryRequestQuery, thunkAPI) => {
  try {
    const resCurrent = await axios.post<SummaryByVessel>(activity.summaryByVessel, requestQuery);
    const resPrevious = await axios.post<SummaryByVessel>(activity.summaryByVessel, {
      ...requestQuery,
      filters: {
        ...requestQuery.filters,
        start_dttm: {
          min: format(subDays(new Date(requestQuery.filters.start_dttm?.min!), 1), "yyyy-MM-dd'T'00:00:00"),
          max: format(subDays(new Date(requestQuery.filters.start_dttm?.min!), 1), "yyyy-MM-dd'T'23:59:59"),
        },
      },
    });
    return { current: resCurrent.data, previous: resPrevious.data };
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

export const fetchActivityBySingleVessel = createAsyncThunk("fetchSingleVessel", async (requestQuery: SummaryRequestQuery, thunkAPI) => {
  try {
    const res = await axios.post<SummaryByVessel>(activity.summaryByVessel, requestQuery);
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

const activityByVessel = createSlice({
  name: "activityByVessel",
  initialState: initialState,
  reducers: {
    cleanupSingleActivity(state) {
      state.singleVesselSummary = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVesselActivities.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchVesselActivities.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchVesselActivities.rejected, (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.status = "error";
      state.data = null;
      state.singleVesselSummary = null;
    });
    builder.addCase(fetchActivityBySingleVessel.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchActivityBySingleVessel.fulfilled, (state, action) => {
      state.status = "success";
      state.singleVesselSummary = action.payload;
      state.error = null;
    });
    builder.addCase(fetchActivityBySingleVessel.rejected, (state, action) => {
      state.status = "error";
      state.error = JSON.stringify(action.payload);
      state.data = null;
      state.singleVesselSummary = null;
    });
  },
});

export const { cleanupSingleActivity } = activityByVessel.actions;
export default activityByVessel.reducer;
