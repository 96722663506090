import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "config/api";
import { ERROR_MESSAGE } from "constants/api/common";
import { GhostVesselState } from "types/ReduxState/MapState.types";
import { AISRequest, AISResponse } from "types/AIS.types";
import { map } from "constants/api/map";

const initialState: GhostVesselState = {
  data: null,
  error: null,
  status: "idle",
  severity: undefined,
};

export const fetchGhostIcon = createAsyncThunk("fetchGhostIcon", async (payload: AISRequest, thunkAPI) => {
  try {
    const res = await axios.post<AISResponse>(map.AIS, payload);
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

const ghostVesselSlice = createSlice({
  name: "ghostVesselSlice",
  initialState: initialState,
  reducers: {
    cleanup(state) {
      state.status = "idle";
      state.data = null;
      state.error = null;
      state.severity = undefined;
    },
    setSeverity(state, action: PayloadAction<"Alert" | "Attention" | "Routine" | "All" | undefined>) {
      state.severity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGhostIcon.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchGhostIcon.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.status = "success";
    });
    builder.addCase(fetchGhostIcon.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = JSON.stringify(action.payload);
      state.severity = undefined;
    });
  },
});

export const { cleanup, setSeverity } = ghostVesselSlice.actions;
export default ghostVesselSlice.reducer;
