import { Menu, MenuItem, Divider } from "@material-ui/core";
import { useModal } from "components/Modal";
import InviteUsersModal from "../InviteUsersModal/InviteUsersModal";
import { useAppSelector } from "hooks/reduxHooks";

interface ProfileMenuProps {
  anchorEl: HTMLElement | null;
  onLogout: () => void;
  onClose: () => void;
  onProfileClick: () => void;
}
const ProfileMenu = (props: ProfileMenuProps) => {
  const { anchorEl, onLogout, onClose, onProfileClick } = props;
  const { modalProps, handleOpen } = useModal({ onOpen: onClose });
  const canInviteUser = useAppSelector((state) => state.permissions.data?.permissions.can_invite_user);

  return (
    <>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        className="custom-dropdown align-left"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <MenuItem onClick={onProfileClick}>My Profile</MenuItem>
        {canInviteUser && (
          <div>
            <MenuItem onClick={handleOpen}>Invite Users</MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
      <InviteUsersModal {...modalProps} />
    </>
  );
};

export default ProfileMenu;
