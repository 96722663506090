import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ValidateTokenAPIResponse, ValidateTokenState } from "../../../../types/ReduxState/ValidatePasswordState.types";
import { ValidateTokenType } from "../../../../types/ValidatePassword.types";
import axios from "../../../../config/api";
import { auth } from "../../../../constants/api/auth";
import { ERROR_MESSAGE } from "../../../../constants/api/common";
import { toast } from "react-toastify";
import { ErrorToast } from "components/Toastbar";

const initialState: ValidateTokenState = {
  status: "idle",
  data: null,
  error: null,
  token_entered: null,
};

export const validateToken = createAsyncThunk("/validate-token", async (reset_token: ValidateTokenType, thunkAPI) => {
  try {
    const res = await axios.post<ValidateTokenAPIResponse>(auth.validateToken, reset_token, { disableErrorToast: true });
    return res.data;
  } catch (error) {
    let errorMessage = ERROR_MESSAGE;
    if (error?.response?.data?.details) {
      if (typeof error.response.data.details === "string") {
        errorMessage = error.response.data.details;
      } else {
        errorMessage = JSON.stringify(error.response.data.details);
      }
    }

    toast.error(<ErrorToast>{errorMessage}</ErrorToast>);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const validateTokenSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token_entered = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateToken.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(validateToken.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload.data;
    });
    builder.addCase(validateToken.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
      state.token_entered = null;
    });
  },
});

export const { setToken } = validateTokenSlice.actions;
export default validateTokenSlice.reducer;
