const Cargo = () => {
  return (
    <span className="icon icon-cargo">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <defs>
          <path d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16z"></path>
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask fill="#fff">
            <use xlinkHref="#bfweey0lha" />
          </mask>
          <use fill="#D1D4E3" fillRule="nonzero" xlinkHref="#bfweey0lha" />
          <g mask="url(#yad8c67i0b)">
            <path
              fill="#9DCFFF"
              fillRule="nonzero"
              d="M32 16.007c0 6.558-3.95 12.194-9.6 14.658h-.868l-.406.492a15.974 15.974  
              0 01-5.11.834c-1.787 0-3.504-.292-5.107-.833l-.48-.538-.795.047C3.982 28.204.03  
              22.567.03 16.007.03 7.178 7.187.022 16.015.022S32 7.178 32 16.007z"
            ></path>
            <path
              fill="#72BBFF"
              fillRule="nonzero"
              d="M16.015 31.991c1.787 0 3.506-.293 5.111-.834l.406-.492h.868c5.65-2.464  
              9.6-8.1 9.6-14.658 0-.263-.007-.524-.02-.784L16.8.04a15.63 15.63 0 00-1.56  
              0v1.713c-1.438.656-1.225 2.911.311 3.286v.94h-4.17v1.165h.395l3.463  10.09c-.13 
              1.634.252 3.194 1.864 3.919a2.622 2.622 0 011.537 2.452  2.561 2.561 0 
              01-.193.91c-.199.49-.55.913-.988 1.206l-.998-.998-.442-.441-.94.94-1.68-1.681h-1.562a4.165  
              4.165 0 001.618 3.304s-3.028 3.773-3.026 3.774l.48.538c.355.12.716.227 1.082.321.91.148 3.12.588  
              4.025.512zm-.777-14.756c-.13 1.634.252 3.194 1.864 3.919"
            ></path>
            <path
              fill="#8B9AA6"
              fillRule="nonzero"
              d="M16.017 27.725c-1.1 0-2.133-.42-2.92-1.19a4.15 4.15 0  01-1.26-2.993h1.56c0 .712.28 1.378.79 
              1.876.51.497 1.182.762  1.895.745 1.377-.033 2.524-1.18 2.557-2.557a2.622 2.622 0  00-1.537-2.452 
              3.16 3.16 0 01-1.864-2.862v-2.545h1.56v2.545c0 .613.373  1.179.95 1.441a4.18 4.18 0 011.224 6.763 4.165 4.165 0 01-2.854 1.228h-.1z"
            ></path>
            <path
              fill="#697C84"
              fillRule="nonzero"
              d="M17.748 19.733a1.598 1.598 0 01-.95-1.441v-2.545h-.78v4.602c.294.336.66.613  
              1.084.805a2.622 2.622 0 011.537 2.452 2.637 2.637 0 01-1.18 2.116 2.572 2.572  0 01-1.329.438l-.048.003h-.064v1.562c.034 
              0 .067 0 .1-.002a4.153 4.153 0  002.854-1.227 4.165 4.165 0 001.228-2.854 4.18 4.18 0 00-2.452-3.909z"
            ></path>
            <g fillRule="nonzero">
              <path
                fill="#FFD300"
                d="M19.92 6.016h-.134m-3.768 11.653l-1.212-.675-1.938-1.079-1.939-1.079-1.22-.679.467-1.585  
                1.261-4.278.001-.001v-.002l.61-2.067 3.97-.003 1.615 4.929z"
              ></path>
              <path fill="#FFB600" d="M19.99 6.221h-3.97v11.448l6.307-3.512-.507-.44.14-.809-.708-2.4-.683-.894.02-1.36-.6-2.033z"></path>
              <path
                fill="#5A5A5A"
                d="M16.888 8.878l-.744-2.273-5.968 5.968-.467 1.584 1.22.68zm-4.84-2.654l-.61  2.068 2.07-2.07zm5.585 
                4.926l-4.765 4.766 1.938 1.079 1.851-1.904z"
              ></path>
            </g>
            <path fill="#FFF" fillRule="nonzero" d="M16.018 24.283l-6.383 6.383c.415.181.84.346 1.274.492l5.992-5.992-.883-.883zm.036 1.801z"></path>
            <path
              fill="#32393F"
              fillRule="nonzero"
              d="M22.327 14.157l-3.759 2.093 3.38-3.38zm-1.753-5.948l-4.556 4.556v2.983l5.243-5.208zM16.018 6.22v3.527l3.527-3.527z"
            ></path>
            <path
              fill="#E3EAEE"
              fillRule="nonzero"
              d="M16.46 24.724l-.442-.441v1.766l.112.111a2.58 2.58 0 001.328-.438l-.998-.998zm2.12  2.119c-.348.27-.73.482-1.137.63l3.683 
              3.684c.433-.146.858-.312 1.274-.493l-3.82-3.82z"
            ></path>
            <g fill="#8B9AA6" fillRule="nonzero">
              <path d="M16.8.046h-.785c.263 0 .524.015.784.041v-.04zm-1.562 0v.041c.257-.026.516-.04.777-.04h-.777z"></path>
              <path
                d="M14.252 3.338c0 .811.55 1.496 1.298 1.702v.94h-4.17v1.165h9.194V5.98h-4.088v-.94a1.769  1.769 0 
              001.298-1.702c0-.694-.402-1.295-.985-1.584V.041a15.686 15.686 0 00-1.56  0v1.713c-.585.289-.987.89-.987 
              1.584zm1.766-.83c.457 0 .83.372.83.83a.83.83 0 01-1.659-.001.83.83 0 01.83-.828z"
              ></path>
            </g>
            <g fill="#697C84" fillRule="nonzero">
              <path d="M16.798.046h-.78c.262 0 .522.015.78.041v-.04z"></path>
              <path
                d="M16.848 3.337c0 .458-.373.83-.83.83v2.978h4.556V5.98h-4.088v-.94c1.537-.375  
              1.75-2.63.313-3.286V.041a15.692 15.692 0 00-.78-.02v2.486c.456 0 .83.372.83.83z"
              ></path>
            </g>
            <g fillRule="nonzero">
              <path fill="#9DCFFF" d="M11.99 31.48c.882.228 1.794.384 2.728.46l-2.728-.46z"></path>
              <path fill="#697C84" d="M15.238.462v.407c.258-.263.517-.407.777-.407h-.777zm1.56.411V.462h-.783c.263 0 .524.145.784.411z"></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default Cargo;
