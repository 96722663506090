import { useRef, useState, MouseEvent } from "react";
import { TableContainer, Table, TableBody, Link } from "@material-ui/core";
import { toast } from "react-toastify";
import { CustomModalProps, Modal, useModalForm } from "components/Modal";
import { InviteUsersContent } from "./InviteUsersModal.styles";
import InviteUserRow from "./InviteUserRow";
import axios from "config/api";
import { user } from "constants/api/userProfile";
import { ErrorToast, SuccessToast } from "components/Toastbar";

interface FormData {
  email: string[];
  fleets_associated: string[];
}
const InviteUsersModal = (props: CustomModalProps) => {
  const { rows, handleAddRow, error, handleDeleteRow, setError } = useModalForm();
  const [loading, setLoading] = useState(false);
  const formData = useRef<Record<number, FormData>>({});

  const onChange = (row: number, email: string[], selected: string[]) => {
    formData.current[row] = {
      email,
      fleets_associated: selected,
    };
  };

  const validateEmail = (email: string) => {
    const emailIDs = Object.values(formData.current)
      .map((e) => e.email)
      .flat();
    return emailIDs.includes(email);
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    if (error) return;

    let failedCount = 0;
    let successCount = 0;
    const params = Object.values(formData.current).map((row) => {
      return row.email
        .map((email) => {
          return [
            {
              user_info: {
                email,
                username: email,
                role: "USER",
              },
              fleets_associated: row.fleets_associated.map((fleet_id) => ({ fleet_id })),
            },
          ];
        })
        .flat();
    });
    setLoading(true);
    for (const row of params) {
      for (const param of row) {
        try {
          await axios.post(user.createUser, param, { disableErrorToast: true });
          successCount++;
        } catch (e) {
          failedCount++;
        }
      }
    }
    setLoading(false);
    if (successCount) {
      toast.success(
        <SuccessToast>
          {successCount} new user{successCount > 1 ? "s" : undefined} {successCount === 1 ? "has" : "have"} been successfully added.
        </SuccessToast>
      );
    }
    if (failedCount) {
      toast.error(
        <ErrorToast>
          {failedCount} user{failedCount > 1 ? "s" : undefined} could not be added.
        </ErrorToast>
      );
    }
    props.onClose?.(e);
  };

  return (
    <Modal
      {...props}
      disableButton={error}
      loading={loading}
      onSubmit={handleSubmit}
      title="Invite Users"
      subtitle="Add one or more email address, separated by commas."
      actionTitle="Send invitation"
    >
      <InviteUsersContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {rows.map((row, index) => (
                <InviteUserRow
                  key={row}
                  row={row}
                  onDelete={handleDeleteRow}
                  onChange={onChange}
                  isDuplicateEmail={validateEmail}
                  setError={index === rows.length - 1 ? setError : undefined}
                  disableDelete={rows.length === 1}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="table-foot-btn">
          <Link component="button" variant="body2" className={error ? "disabled" : "".trim()} onClick={handleAddRow} disabled={error}>
            Add additional Users & Fleet Associations +
          </Link>
        </div>
      </InviteUsersContent>
    </Modal>
  );
};

export default InviteUsersModal;
