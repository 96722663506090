interface ReactionIconProps {
  size?: number;
  color?: string;
}
const Reaction = (props: ReactionIconProps) => {
  const { size = 16, color = "#333" } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g fill={color} fillRule="evenodd">
        <g fillRule="nonzero">
          <path d="M11.5 8h-7a.5.5 0 0 0-.5.5 4 4 0 0 0 8 0 .5.5 0 0 0-.5-.5zM8 11.5A3 3 0 0 1 5.04 9h5.92A3 3 0 0 1 8 11.5z" />
          <path d="M8 0c1.235 0 2.404.28 3.448.78-.239.24-.433.524-.568.84a7 7 0 1 0 3.5 3.5c.316-.135.6-.33.84-.569A8 8 0 1 1 8 0z" />
          <circle cx="5.5" cy="5.5" r="1" />
          <circle cx="10.5" cy="5.5" r="1" />
        </g>
        <path d="M13.488.975h-1v3.333h1z" />
        <path d="M14.802 3.011v-.667H11.47v.667z" />
      </g>
    </svg>
  );
};

export default Reaction;
