import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActivityBarState } from "types/ReduxState/ActivityBarState.types";
import { ActivityBarFilters, ActivityBar } from "types/ActivitBar.types";
import axios from "config/api";
import { activity } from "constants/api/activity";
import { ERROR_MESSAGE } from "constants/api/common";

const initialState: ActivityBarState = {
  status: "idle",
  data: null,
  error: null,
};

export const ActivityBarData = createAsyncThunk("activitybar", async (filters: ActivityBarFilters, thunkAPI) => {
  try {
    const res = await axios.post<ActivityBar>(activity.activityBar, filters);
    return res.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return thunkAPI.rejectWithValue(ERROR_MESSAGE);
  }
});

export const ActivityBarSlice = createSlice({
  name: "VesselHeader",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ActivityBarData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(ActivityBarData.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(ActivityBarData.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });
  },
});

export default ActivityBarSlice.reducer;
