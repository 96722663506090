import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

interface InfiniteScrollOptions {
  /** Page number will be incremented by offset everytime
   * last element is visible on the screen. */
  incrementOffset: number;
}
interface ReturnType {
  /** Callback ref that implements intersection observer.
   * attach this to the last react element of the list. */
  lastElementRef: (node: HTMLElement) => void;
  /** Page number to call the API. */
  pageNumber: number;
  /** Set if the current page number is last or not. */
  setHasMore: Dispatch<SetStateAction<boolean>>;
  /** Max number of rows per response.  */
  limit: number;
  /** Set Page number. */
  setPageNumber: Dispatch<SetStateAction<number>>;
  /** Is the page number last or has more pages on the server */
  hasMore: boolean;
}
/**
 * Hook to implement infinite scrolling for paginated APIs.
 */
function useInfiniteScroll(options: InfiniteScrollOptions = { incrementOffset: 10 }): ReturnType {
  const [pageNumber, setPageNumber] = useState(0);
  // Whether current pageNumber is last or not
  const [hasMore, setHasMore] = useState(true);
  let observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    return () => {
      observer.current?.disconnect();
    };
  }, []);

  const lastElementRef = useCallback(
    (node: HTMLElement) => {
      if (observer.current) observer.current?.disconnect();
      observer.current = new IntersectionObserver((elements) => {
        if (elements[0].isIntersecting && hasMore) {
          setPageNumber((page) => page + (options?.incrementOffset ?? 1));
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, options?.incrementOffset]
  );

  return { lastElementRef, pageNumber, setHasMore, limit: options?.incrementOffset ?? 1, setPageNumber, hasMore };
}

export default useInfiniteScroll;
