interface NotificationIconProps {
  size?: number | string;
  color?: string;
}
const Notifications = (props: NotificationIconProps) => {
  const { size = "1em", color = "#000" } = props;

  return (
    <span className="icon icon-notifications">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="-1 0 24 24">
        <path
          fill={color}
          fillRule="nonzero"
          d="M19.974 17.014a7.63 7.63 0 01-2.236-5.398V9.723a6.432 
          6.432 0 00-4.868-6.238V1.56a1.56 1.56 0 00-3.121 0v1.925A6.43 6.43
          0 004.88 9.723v1.893a7.632 7.632 0 01-2.236 5.398A2.2 2.2 0 004.2
          20.77h3.88a3.23 3.23 0 106.459 0h3.879a2.201 2.201 0 001.556-3.756zm-8.665
          5.4a1.645 1.645 0 01-1.644-1.644h3.289c0 .907-.738 1.645-1.645 1.645zm7.677-3.609a.614.614
          0 01-.568.38H4.2a.614.614 0 01-.434-1.05 9.163 9.163 0 002.7-6.519V9.723a4.849 4.849
          0 014.843-4.844 4.85 4.85 0 014.844 4.844v1.893c0 2.463.958 4.778 2.7 6.52a.614.614 0 01.133.67z"
        ></path>
      </svg>
    </span>
  );
};

export default Notifications;
