import styled from "styled-components";
import { rem } from "config/variable.styles";

export const FlagIcon = styled.div`
  height: ${rem(23)};
  width: ${rem(23)};
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .gray-bg {
    background-color: lightgray;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
