import { ReactNode } from "react";
import AlertCard from "./AlertCard";

interface AreaSectionProps {
  children?: ReactNode;
  title: string;
  className?: string;
}
export const AlertSection = (props: AreaSectionProps) => {
  const { title, children, className } = props;
  return (
    <div className={className}>
      <p className="day">{title}</p>
      {children}
    </div>
  );
};

const AlertTabList = () => {
  return (
    <>
      <AlertSection title="Today" className="alertToday">
        <AlertCard fullName="Manifest Developer" text="Test" time="" warning />
        <AlertCard fullName="Manifest Developer" text="Test" time="" />
      </AlertSection>
      <AlertSection title="Older" className="alertOlder">
        <AlertCard fullName="Manifest Developer" text="Test" time="" warning />
        <AlertCard fullName="Manifest Developer" text="Test" time="" />
      </AlertSection>
    </>
  );
};

export default AlertTabList;
