const Safety = () => {
  return (
    <span className="icon icon-safety">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <g fill="none" fillRule="nonzero">
          <circle cx="16" cy="16" r="16" fill="#01579A"></circle>
          <path
            fill="#034A7A"
            d="M31.89 17.867L24.223 10.2l-.212.947-5.364-5.365-14.73 15.292L14.8 31.955c.397.03.797.045 1.201.045 8.205 0 14.966-6.176 15.89-14.133z"
          ></path>
          <path fill="#FCBF29" d="M26.887 20.531H5.113v-3.196C5.113 11.322 9.987 6.448 16 6.448c6.013 0 10.887 4.874 10.887 10.887v3.196z"></path>
          <path fill="#FEA831" d="M26.887 17.335c0-6.013-4.874-10.887-10.887-10.887h-.027v14.083h10.914v-3.196z"></path>
          <path fill="#FEDB41" d="M13.354 5.781h5.292v15.083h-5.292z"></path>
          <path fill="#FCBF29" d="M15.973 5.781h2.673v15.083h-2.673z"></path>
          <path fill="#FEA831" d="M3.917 16.989h24.167v4.083H3.917z"></path>
          <path fill="#FE8821" d="M15.973 16.989h12.11v4.083h-12.11z"></path>
        </g>
      </svg>
    </span>
  );
};

export default Safety;
