import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: { videoStatus: false, activityStatus: false, videoActivityStatus: false },
};

export const setVideoStatus = createSlice({
  name: "videoDrawerStatus",
  initialState,
  reducers: {
    setVideoOpenStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setVideoOpenStatus } = setVideoStatus.actions;

export default setVideoStatus.reducer;
