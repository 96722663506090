import { useState, ChangeEvent, useEffect } from "react";
import { Tab } from "@material-ui/core";
import { NotificationContentStyle, NotificationTab } from "./NotificationContent.styles";
import { TabPanel, getTabProps } from "./TabPanel";
import Comments from "./Comments";
import Alerts from "./Alerts";
import { isPreviewEnabled } from "utils";
import { useAppDispatch } from "hooks/reduxHooks";
import { clearNotifications } from "services/redux/reducers/Comments/notification.slice";

interface NotificationContentProps {
  onNotificationClose: () => void;
}
const NotificationContent = ({ onNotificationClose }: NotificationContentProps) => {
  const [value, setValue] = useState(0);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch]);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <NotificationContentStyle>
      <div className="tab-head">
        <h2 className="title">Notifications</h2>
        <NotificationTab value={value} onChange={handleChange} aria-label="notification tabs">
          <Tab label="Comments" {...getTabProps(0)} />
          {isPreviewEnabled && <Tab label="Alerts" {...getTabProps(1)} />}
        </NotificationTab>
      </div>
      <div className="tab-content">
        <TabPanel value={value} index={0}>
          <Comments onNotificationClose={onNotificationClose} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Alerts />
        </TabPanel>
      </div>
    </NotificationContentStyle>
  );
};

export default NotificationContent;
