import { AlertCardContainer, ReadDot } from "../NotificationContent.styles";

interface AlertCardProps {
  warning?: boolean;
  fullName: string;
  text: string;
  time: string;
}
const AlertCard = (props: AlertCardProps) => {
  const { warning, fullName, text } = props;

  return (
    <AlertCardContainer warning={warning}>
      <ReadDot />
      <h3>{fullName}</h3>
      <p>{text}</p>
      <div className="alert-info">
        <p>
          11:51:00 AM - 12:24:19 PM UTC / 00:23:44 <strong>(3 hr ago)</strong>
        </p>
      </div>
    </AlertCardContainer>
  );
};

export default AlertCard;
