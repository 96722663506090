import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { VesselHeaderState } from "types/ReduxState/VesselHeader.types";
import { VesselHeader, VesselId } from "types/Vessel.types";
import axios from "config/api";
import { vessel } from "constants/api/vessel";
import { ERROR_MESSAGE } from "constants/api/common";
import { RootState } from "services/redux/store";

const initialState: VesselHeaderState = {
  status: "idle",
  data: null,
  error: null,
};

export const VesselHeaderData = createAsyncThunk(
  "vessel/vesselheaders",
  async (VesselId: VesselId, thunkAPI) => {
    try {
      const res = await axios.get<VesselHeader>(vessel.details(VesselId.id));
      return res.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      return thunkAPI.rejectWithValue(ERROR_MESSAGE);
    }
  },
  {
    condition: ({ id }, { getState }) => {
      const state = getState() as RootState;
      if (state?.vesselHeader?.data?.id === id) {
        return false;
      }
      return true;
    },
  }
);

export const VesselHeaderSlice = createSlice({
  name: "VesselHeader",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(VesselHeaderData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(VesselHeaderData.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(VesselHeaderData.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });
  },
});

export default VesselHeaderSlice.reducer;
