import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type PlayedSet = {
  [eventID: string]: {
    eventID: string;
    totalPlayed?: number;
    videos: { [url: string]: { played: number; url: string } };
  };
};

const initialState = {
  isPlayerLoaded: false,
  played: {} as PlayedSet,
};

export const setVideoControlsSlice = createSlice({
  name: "played",
  initialState: initialState,
  reducers: {
    setIsPlayerLoaded: (state, action: PayloadAction<boolean>) => {
      state.isPlayerLoaded = action.payload;
    },
    setVideoPlayed: (state, action: PayloadAction<{ eventID: string; videoCount: number } & { video: PlayedSet[""]["videos"][""] }>) => {
      if (action.payload.eventID in state.played) {
        let allVideos = { ...state.played[action.payload.eventID].videos, [action.payload.video.url]: action.payload.video };
        let totalPlayedVideos = Object.values(allVideos).reduce((total, item) => (total += item.played), 0);
        state.played[action.payload.eventID].eventID = action.payload.eventID;
        state.played[action.payload.eventID].totalPlayed = totalPlayedVideos / action.payload.videoCount;
        state.played[action.payload.eventID].videos = {
          ...state.played[action.payload.eventID].videos,
          [action.payload.video.url]: action.payload.video,
        };
      } else {
        state.played[action.payload.eventID] = {
          eventID: action.payload.eventID,
          totalPlayed: 0,
          videos: { [action.payload.video.url]: action.payload.video },
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVideoPlayed, setIsPlayerLoaded } = setVideoControlsSlice.actions;

export default setVideoControlsSlice.reducer;
