import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { UserAPIResponse, UserState } from "types/ReduxState/UserState.types";
import { LoginCredentials, UserProfileResponse } from "types/User.types";
import axios from "config/api";
import { auth } from "constants/api/auth";
import { user } from "constants/api/userProfile";
import { ERROR_MESSAGE } from "constants/api/common";
import { toast } from "react-toastify";
import { ErrorToast } from "components/Toastbar";
import { fetchUserPermissions } from "./permission.slice";

const initialState: UserState = {
  status: "idle",
  data: null,
  error: null,
};

export const loginUser = createAsyncThunk("user/login", async (loginCredentials: LoginCredentials, thunkAPI) => {
  try {
    const res = await axios.post<UserAPIResponse>(auth.login, loginCredentials, { disableErrorToast: true });
    return res.data;
  } catch (error) {
    let errorMessage = ERROR_MESSAGE;
    if (error?.response?.data?.details) {
      if (typeof error.response.data.details === "string") {
        errorMessage = error.response.data.details;
      } else {
        errorMessage = JSON.stringify(error.response.data.details);
      }
    }

    toast.error(<ErrorToast>{errorMessage}</ErrorToast>);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const getCurrentUser = createAsyncThunk("getCurrentUser", async (_, { getState, rejectWithValue, dispatch }) => {
  //@ts-ignore
  const state = getState().user as UserState;
  const token = localStorage.getItem("token");
  try {
    if (!token || state.data) {
      return undefined;
    } else {
      const response = await axios.get<UserProfileResponse>(user.currentUser);
      dispatch(fetchUserPermissions());
      return response.data;
    }
  } catch (err) {
    return rejectWithValue(ERROR_MESSAGE);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<UserProfileResponse>) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { token, ...userInfo } = action.payload;
      localStorage.setItem("user", JSON.stringify(userInfo));
      localStorage.setItem("token", token);
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });
    builder.addCase(getCurrentUser.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.status = "success";
      if (action.payload == null) return;
      state.data = action.payload;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
