import { rem } from "config/variable.styles";
import styled from "styled-components";

export const DateRangeRoot = styled.div`
  position: relative;
  .date-picker-popup {
    position: absolute;
    top: ${rem(20)};
    left: 0;
    z-index: 9999;
    .MuiPaper-elevation5 {
      position: relative;
      box-shadow: inherit;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -1rem;
        left: 0;
        border-bottom: 1rem solid #fff;
        border-right: 1.125rem solid transparent;
      }
      .materialui-daterange-picker-MuiGrid-root-7 {
        &.materialui-daterange-picker-makeStyles-header-4 {
          padding: 20px 55px;
        }
        .MuiList-root {
          .MuiListItem-button {
            white-space: nowrap;
          }
        }
      }
    }
  }
`;

export const RangeControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .date-range-text {
    white-space: nowrap;
  }
  .icon {
    margin-right: ${rem(5)};
    display: block;
    height: ${rem(16)};
  }
`;
