import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { memo, useEffect } from "react";
import { isToday } from "date-fns";
import { RecentComment } from "types/Comments.types";
import { NoNotificationPlaceholder } from "../NotificationContent.styles";
import NotificationIcon from "components/Icon/Notifications";
import Spinner from "components/Loaders/Spinner";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useAutoRead from "hooks/useAutoRead";
import { fetchRecentComments } from "services/redux/reducers/Comments/comments.actions";
import { isEmptyObject } from "utils";
import { CircularProgress } from "@material-ui/core";
import CommentSection from "./CommentSection";

interface CommentsTabListProps {
  onNotificationClose: () => void;
}
const CommentsTabList = ({ onNotificationClose }: CommentsTabListProps) => {
  const { data, status } = useAppSelector((state) => state.notifications.recentComments);
  const dispatch = useAppDispatch();
  const ref = useAutoRead([data]);
  const { lastElementRef, pageNumber, setHasMore, limit } = useInfiniteScroll();

  useEffect(() => {
    dispatch(
      fetchRecentComments({
        requestPayload: {
          result_range: {
            limit,
            offset: pageNumber,
          },
        },
      })
    )
      .unwrap()
      .then((response) => {
        if (isEmptyObject(response) || response.comments.length === 0 || response.comments.length < limit) {
          setHasMore(false);
        }
      })
      .catch(() => setHasMore(false));
  }, [dispatch, pageNumber, setHasMore, limit]);

  if (status === "loading") {
    return <Spinner />;
  }

  // Return if no recent comments are available
  if (!data || (data && data.comments.length === 0)) {
    return (
      <NoNotificationPlaceholder>
        <NotificationIcon size={50} color="#dddddd" />
        <span className="text">You currently have no notifications. We’ll notify you when something new arrives.</span>
      </NoNotificationPlaceholder>
    );
  }

  // Split the comments array into 2 by date - posted today and older
  const [today, older] = data.comments.reduce<RecentComment[][]>(
    ([current, rest], item) => {
      if (isToday(new Date(item.comment.created_at))) {
        current.push(item);
      } else {
        rest.push(item);
      }
      return [current, rest];
    },
    [[], []]
  );

  return (
    <div ref={ref}>
      {today.length > 0 && (
        <CommentSection
          onNotificationClose={onNotificationClose}
          title="Today"
          /** Attaching ref to last element of list */
          ref={older.length === 0 ? lastElementRef : undefined}
          comments={today}
        />
      )}
      {older.length > 0 && (
        <CommentSection
          onNotificationClose={onNotificationClose}
          title="Older"
          /** Attaching ref to last element of list */
          ref={today.length === 0 ? lastElementRef : undefined}
          comments={older}
        />
      )}
      {status === "refetching" && (
        <div className="small-spinner">
          <CircularProgress className="small-spinner" />
        </div>
      )}
    </div>
  );
};

export default memo(CommentsTabList);
