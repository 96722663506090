import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EventTimelineState, EventTimelineAPIResponse, EventTimelineRequest } from "types/ReduxState/EventTimelineState.types";
import axios from "config/api";
import { activity } from "constants/api/activity";
import { ERROR_MESSAGE } from "constants/api/common";

const initialState: EventTimelineState = {
  status: "idle",
  data: null,
  error: null,
  query: null,
};

export const fetchEventTimeline = createAsyncThunk("eventTimeline/fetch", async (requestQuery: EventTimelineRequest, thunkAPI) => {
  try {
    const res = await axios.post<EventTimelineAPIResponse>(activity.activityTimeline, requestQuery);
    return { response: res.data, query: requestQuery };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message ? error.response.data.message : ERROR_MESSAGE);
  }
});

export const eventTimelineSlice = createSlice({
  name: "eventTimeline",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEventTimeline.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchEventTimeline.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload.response.timeline;
      state.query = action.payload.query;
    });
    builder.addCase(fetchEventTimeline.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });
  },
});

export default eventTimelineSlice.reducer;
