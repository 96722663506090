const Bridge = () => {
  return (
    <span className="icon icon-bridge">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <g fill="none" fillRule="nonzero">
          <circle cx="16" cy="16" r="16" fill="#FFCF35"></circle>
          <path fill="#FFB344" d="M32 16c0-.356-.013-.71-.036-1.06L21.028 4.004l-4.848 5.71 13.897 13.898A15.936 15.936 0 0032 16z"></path>
          <path fill="#758F9A" d="M21.333 7.111H10.667v-2.28c0-.705.57-1.275 1.275-1.275h8.116c.705 0 1.275.57 1.275 1.274v2.281z"></path>
          <path fill="#4B7689" d="M21.333 4.83c0-.704-.57-1.274-1.275-1.274H16V7.11h5.333V4.83z"></path>
          <path
            fill="#FFF"
            d="M21.755 17.347h-11.51c-.75 0-1.356-.607-1.356-1.356V8.036c0-.749.607-1.356 
            1.356-1.356h11.51c.75 0 1.356.607 1.356 1.356v7.955c0 .749-.607 1.356-1.356 1.356z"
          ></path>
          <path fill="#E0E0E0" d="M21.755 6.68H16v10.667h5.755c.75 0 1.356-.607 1.356-1.356V8.036c0-.749-.607-1.356-1.356-1.356z"></path>
          <path fill="#A5E1EB" d="M10.667 8.458h10.667v3.556H10.667z"></path>
          <path fill="#78BED2" d="M16 8.458h5.333v3.556H16z"></path>
          <path fill="#175D76" d="M23.517 28.1s4.705-3.521 4.981-12.452l-12.453-4.981-12.452 4.98s-1.183 5.384 4.98 12.454h14.944z"></path>
          <path fill="#00263F" d="M16.045 10.667l-.045.018V28.1h7.517s4.704-3.522 4.981-12.453l-12.453-4.981z"></path>
          <path
            fill="#00BAFF"
            d="M.95 21.444C3.179 27.6 9.076 32 16 32c6.253 0 11.668-3.588 14.3-8.817-2.1-2.42-3.903-4.016-9.432-1.403-8 3.781-15.8 1.484-19.917-.336z"
          ></path>
          <path
            fill="#008CC3"
            d="M30.3 23.183c-2.1-2.42-3.903-4.016-9.432-1.403A21.092 21.092 0 0116 23.396V32c6.253 0 11.668-3.588 14.3-8.817z"
          ></path>
        </g>
      </svg>
    </span>
  );
};

export default Bridge;
