import { createAsyncThunk } from "@reduxjs/toolkit";
import { ERROR_MESSAGE } from "constants/api/common";
import axios from "config/api";
import { comments } from "constants/api/comments";
import {
  AddCommentRequest,
  CommentInfo,
  GetCommentsResponse,
  ReadCountRequestPayload,
  ReadCountResponse,
  ReadStatus,
  RecentCommentsResponse,
} from "types/Comments.types";
import { ReduxState } from "types/ReduxState/common.types";

type InitialState = {
  /** Holds state for all the comments */
  commentsList: ReduxState<GetCommentsResponse | null>;
  /** Holds the state for POST endpoint to add a new comment */
  postComment: ReduxState<CommentInfo | null>;
  /** Holds the state for DELETE endpoint to delete comment */
  deleteComment: ReduxState<CommentInfo | null>;
  /** Holds the state for PATCH endpoint to edit comment */
  editComment: ReduxState<{ text: string } | null>;
};
/** InitialState for comments */
export const initialState: InitialState = {
  commentsList: {
    data: null,
    status: "idle",
    error: null,
  },
  postComment: {
    data: null,
    status: "idle",
    error: null,
  },
  deleteComment: {
    data: null,
    status: "idle",
    error: null,
  },
  editComment: {
    data: null,
    status: "idle",
    error: null,
  },
};

interface AddCommentPayload extends AddCommentRequest {
  activityID: string;
}
/**
 * @returns {data} Data received from API about added comment
 * @returns {activityID} ActivityID to which the comment belongs
 * @returns {type} Whether the created comment is a standalone comment or a reply
 * @returns {reply_to} arent comment id if created comment is a reply
 */
export const addNewComment = createAsyncThunk("addComment", async (payload: AddCommentPayload, thunkAPI) => {
  try {
    const { activityID, ...requestPayload } = payload;
    const response = await axios.post<CommentInfo>(comments.post(activityID), requestPayload);
    return { data: response.data, activityID, type: payload.reply_to ? "reply" : "comment", reply_to: requestPayload.reply_to };
  } catch (err) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGE);
  }
});

export const fetchComments = createAsyncThunk("fetchComments", async (activityID: string, thunkAPI) => {
  try {
    const response = await axios.get<GetCommentsResponse>(comments.get(activityID));
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg ?? ERROR_MESSAGE);
  }
});

interface RecentCommentsPayload {
  requestPayload: {
    result_range: {
      offset: number;
      limit: number;
    };
  };
}
export const fetchRecentComments = createAsyncThunk("fetchRecentComments", async (payload: RecentCommentsPayload, { rejectWithValue }) => {
  try {
    const { requestPayload } = payload;
    const response = await axios.post<RecentCommentsResponse>(comments.recent, requestPayload);
    return response.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.msg ?? ERROR_MESSAGE);
  }
});

interface DeleteCommentPayload {
  commentID: string;
  type?: "reply" | "comment";
  parentCommentID?: string;
}
/**
 * @returns {data} Data received from API about deleted comment
 * @returns {commentID} commentID of a reply
 * @returns {type} Whether the deleted comment is a standalone comment or a reply
 * @returns {parentCommentID} comment ID of parent (only applicable for replies)
 */
export const deleteComment = createAsyncThunk("deleteComment", async (payload: DeleteCommentPayload, { rejectWithValue }) => {
  try {
    const response = await axios.delete<CommentInfo>(comments.delete(payload.commentID));
    return { data: response.data, type: payload.type, commentID: payload.commentID, parentCommentId: payload.parentCommentID };
  } catch (err) {
    return rejectWithValue(err?.response?.data?.msg ?? ERROR_MESSAGE);
  }
});

interface EditCommentPayload extends DeleteCommentPayload {
  text: string;
}
/**
 * @returns {data} Data received from API about edited comment
 * @returns {commentID} commentID of a reply
 * @returns {type} Whether the edited comment is a standalone comment or a reply
 * @returns {parentCommentID} comment ID of parent (only applicable for replies)
 */
export const editComment = createAsyncThunk("editComment", async (payload: EditCommentPayload, { rejectWithValue }) => {
  try {
    const response = await axios.patch<CommentInfo>(comments.edit(payload.commentID), { text: payload.text });
    return { data: response.data, type: payload.type, commentID: payload.commentID, parentCommentID: payload.parentCommentID };
  } catch (err) {
    return rejectWithValue(err?.response?.data?.msg ?? ERROR_MESSAGE);
  }
});

export const fetchNotificationCount = createAsyncThunk("fetchNotificationCount", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<ReadCountResponse>(comments.notificationCount);
    return response.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.msg ?? ERROR_MESSAGE);
  }
});

interface ReadStatusPayload {
  requestPayload: ReadCountRequestPayload;
  read_status: ReadStatus;
  type?: "reply" | "comment";
  parentCommentID?: string;
  commentID?: string;
}
/**
 * type is required while calling this async action from comment drawer
 * parentCommentID is required to mark a reply as read/unread
 */
export const setReadStatus = createAsyncThunk("setReadStatus", async (payload: ReadStatusPayload, { rejectWithValue, dispatch }) => {
  try {
    const { requestPayload, type, commentID, parentCommentID, read_status } = payload;
    const response = await axios.post(comments.readStatus, requestPayload);
    dispatch(fetchNotificationCount());
    return { data: response.data, type, commentID, parentCommentID, read_status };
  } catch (err) {
    return rejectWithValue(err?.response?.data?.msg ?? ERROR_MESSAGE);
  }
});
