import { OperationType, SeverityType } from "../types/Event.types";
import Alert from "../components/Icon/Critical";
import Attention from "../components/Icon/Attention";
import Routine from "../components/Icon/Routine";
import Maintenance from "../components/Icon/Maintenance";
import Cargo from "../components/Icon/Cargo";
import Bridge from "../components/Icon/Bridge";
import Safety from "../components/Icon/Safety";
import AllActivities from "components/Icon/AllActivities";
import AllSeverities from "components/Icon/AllSeverities";
import { icon } from "leaflet";
import { vesselGhostIcon, vesselDBMarkers, globalDBMarkers } from "config/images";

export function getOperationTypeAbbr(operationType?: OperationType) {
  switch (operationType) {
    case "BRIDGE":
      return "BRG";
    case "CARGO":
      return "CRG";
    case "MAINTENANCE":
      return "MNT";
    case "SAFETY":
      return "SFT";
    default:
      break;
  }
}

export function renderOperationTypeIcon(operationType?: OperationType) {
  switch (operationType) {
    case "BRIDGE":
      return <Bridge />;
    case "CARGO":
      return <Cargo />;
    case "MAINTENANCE":
      return <Maintenance />;
    case "SAFETY":
      return <Safety />;
    case "ALL":
      return <AllActivities />;
    case "BUNKERING":
    default:
      break;
  }
}

export function renderSeverityIcon(severityType?: SeverityType) {
  switch (severityType) {
    case "Alert":
      return <Alert />;
    case "Attention":
      return <Attention />;
    case "Routine":
      return <Routine />;
    case "All":
      return <AllSeverities />;
    default:
      break;
  }
}

// Get left border color for table cell from severity
export const getColor = (severity: string | undefined) => {
  switch (severity) {
    case "Alert":
      return "#f05e5c";
    case "Attention":
      return "#ffbc57";
    case "Routine":
      return "#3cdaac";
    default:
      return "#828382";
  }
};

export const getVesselGhostIcon = (severity: string | undefined) => {
  switch (severity) {
    case "Alert":
      return icon({
        iconUrl: vesselGhostIcon.alert,
        iconSize: [40, 40],
      });
    case "Attention":
      return icon({
        iconUrl: vesselGhostIcon.attention,
        iconSize: [40, 40],
      });
    case "Routine":
    case undefined:
    default:
      return icon({
        iconUrl: vesselGhostIcon.routine,
        iconSize: [40, 40],
      });
  }
};
export const getVesselDBMarker = (severity?: string) => {
  switch (severity) {
    case "Alert":
      return icon({
        iconUrl: vesselDBMarkers.alert,
        iconSize: [40, 40],
      });
    case "Attention":
      return icon({
        iconUrl: vesselDBMarkers.attention,
        iconSize: [40, 40],
      });
    case "Routine":
      return icon({
        iconUrl: vesselDBMarkers.routine,
        iconSize: [40, 40],
      });
    default:
      return icon({
        iconUrl: vesselDBMarkers.default,
        iconSize: [40, 40],
      });
  }
};

export const getGlobalDBMarker = (severity: string | undefined) => {
  switch (severity) {
    case "Alert":
      return icon({
        iconUrl: globalDBMarkers.alert,
        iconSize: [13, 33],
      });
    case "Attention":
      return icon({
        iconUrl: globalDBMarkers.attention,
        iconSize: [13, 33],
      });
    case "Routine":
      return icon({
        iconUrl: globalDBMarkers.routine,
        iconSize: [13, 33],
      });
    default:
      return icon({
        iconUrl: globalDBMarkers.default,
        iconSize: [13, 33],
      });
  }
};
