import { useTransfer } from "./useTransfer";

function useTransferVessel(rowId?: string[]) {
  return useTransfer({
    mode: "vessels",
    tranform: (_, vessel) =>
      rowId?.includes(vessel.id)
        ? { id: vessel.id, name: vessel.name, selected: true, country_iso: vessel.country_iso ? vessel.country_iso : undefined }
        : { id: vessel.id, name: vessel.name, selected: false, country_iso: vessel.country_iso ? vessel.country_iso : undefined },
  });
}

export { useTransferVessel };
