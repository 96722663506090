import { Route, Redirect, RouteProps } from "react-router-dom";
import routes from "./config/routes";

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  if (!!localStorage.getItem("token") && !!localStorage.getItem("user")) {
    return <Route {...rest} />;
  } else {
    return <Redirect to={routes.login.path} />;
  }
};
export default PrivateRoute;
