import { useEffect, useRef, useState } from "react";
import { TransferItem } from "components/Transfer/Transfer.types";
import { forEachVessels, forEachFleets } from "utils";
import useFleets from "hooks/useFleets";
import { Fleet, Vessel } from "types/Fleet.types";

type Options =
  | {
      mode: "vessels";
      tranform: (fleet: Fleet, vessel: Vessel) => TransferItem;
    }
  | {
      mode: "fleets";
      transform: (fleet: Fleet) => TransferItem;
    };
/**
 * Helper hook to manage state for transfer component.
 * Returns transfer component prop getter and selected items.
 */
function useTransfer(options: Options) {
  const [items, setItems] = useState<TransferItem[]>([]);
  const initialState = useRef<TransferItem[]>([]);
  const { data, loading } = useFleets();
  const selected = items.filter((e) => e.selected);

  useEffect(() => {
    if (!data || !data.fleets.length || items.length) return;
    const fleets =
      options.mode === "vessels"
        ? forEachVessels(data, options.tranform, { removeDuplicates: true })
        : forEachFleets(data, options.transform, { removeDuplicates: true });
    if (!fleets) return;
    setItems(fleets);
    initialState.current = fleets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function reset() {
    setItems(initialState.current);
  }

  return {
    selected,
    reset,
    props: {
      items,
      onChange: setItems,
      loading,
    },
  };
}

export { useTransfer };
