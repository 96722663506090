import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "config/api";
import { BridgeWatchQuery, BridgeWatchResponse } from "types/Reports.types";
import { reports } from "constants/api/reports";
import { ERROR_MESSAGE } from "constants/api/common";
import { ReduxState } from "types/ReduxState/common.types";

const initialState: ReduxState<BridgeWatchResponse> = {
  status: "idle",
  data: null,
  error: null,
};

export const getBridgeWatchDetails = createAsyncThunk("bridgeWatch", async (payload: BridgeWatchQuery, { rejectWithValue }) => {
  try {
    const response = await axios.post<BridgeWatchResponse>(reports.bridgeWatch, payload);
    return response.data;
  } catch (err) {
    return rejectWithValue(JSON.stringify(err.response?.data ?? ERROR_MESSAGE));
  }
});

const reportsSlice = createSlice({
  name: "bridgeWatch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBridgeWatchDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getBridgeWatchDetails.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getBridgeWatchDetails.rejected, (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.status = "error";
      state.data = null;
    });
  },
});

export default reportsSlice.reducer;
