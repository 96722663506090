import { useState, ChangeEvent, MouseEvent, Dispatch, SetStateAction, useRef } from "react";
import { TableRow, TableCell, TextField, CircularProgress, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";

import FleetsDropdown from "components/FleetsDropdpwn";
import Delete from "components/Icon/Delete";
import Chip from "components/Chip";
import { useTransferFleets } from "components/Transfer";
import axios from "config/api";
import { user, EMAIL_REGEX } from "constants/api/userProfile";
import { ErrorToast } from "components/Toastbar";
import useUpdateEffect from "hooks/useUpdateEffect";
import Tooltip from "components/Tooltip";

interface InviteUserRowProps {
  onDelete: (row: number) => void;
  onChange?: (row: number, emails: string[], selected: string[]) => void;
  /**
   * Function to check if entered email already exists in any of the rows.
   * Returns true if entered email is valid.
   */
  isDuplicateEmail: (email: string) => boolean;
  setError?: Dispatch<SetStateAction<boolean>>;
  row: number;
  disableDelete?: boolean;
}
const InviteUserRow = (props: InviteUserRowProps) => {
  const { onDelete, row, onChange, isDuplicateEmail, setError, disableDelete } = props;
  const [value, setValue] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { props: dropdownProps, selected } = useTransferFleets();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useUpdateEffect(() => {
    onChange?.(
      row,
      value,
      selected.map((e) => e.id)
    );
    setError?.((error) => {
      if ((!value.length || !selected.length) && !error) {
        return true;
      } else if (value.length > 0 && selected.length > 0 && error) {
        return false;
      }
      return error;
    });
  }, [value, selected]);

  const handleChange = async (e: ChangeEvent<{}>, v: any) => {
    const users = [...v] as string[];
    const email = users[users.length - 1]?.trim() as string | undefined;
    if (!email) return;
    if (!EMAIL_REGEX.test(email)) {
      toast.error(<ErrorToast>Please enter a valid email.</ErrorToast>);
      return;
    }
    if (value.includes(email) || isDuplicateEmail(email)) {
      toast.error(<ErrorToast>Email {email} is already entered.</ErrorToast>);
      return;
    }

    try {
      setLoading(true);
      await axios.post(
        user.listQuery,
        {
          filters: {
            email: [email],
          },
          result_range: {
            offset: 0,
            limit: 10,
          },
        },
        { disableErrorToast: true }
      );
      toast.error(<ErrorToast>User {email} already exists.</ErrorToast>);
    } catch (e) {
      setValue(users);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>, label: string) => {
    e.stopPropagation();
    setValue((items) => items.filter((email) => email !== label));
  };

  return (
    <TableRow>
      <TableCell scope="row">
        <Autocomplete
          className="custom-autocomplete"
          multiple
          freeSolo
          id="tags-outlined"
          renderTags={(selectedValue: any) => {
            const items = [...selectedValue] as string[];
            const values = items.slice(0, 3);
            return (
              <>
                {values.map((label) => (
                  <Chip label={label} key={label} enableDelete onDeleteChip={(e) => handleDelete(e, label)} />
                ))}
                <Tooltip
                  arrow
                  placement="top-start"
                  interactive
                  title={
                    <div className="vertical">
                      {value.slice(3).map((e) => (
                        <Chip key={e} label={e} enableDelete onDeleteChip={(event) => handleDelete(event, e)} />
                      ))}
                    </div>
                  }
                >
                  {value.length > 3 ? <span>+{value.length - 3}</span> : <></>}
                </Tooltip>
              </>
            );
          }}
          options={[]}
          onChange={handleChange}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                inputRef,
                endAdornment: <>{loading && <CircularProgress size={25} />}</>,
              }}
              variant="outlined"
              placeholder={!value.length ? "Enter email addresses separated by commas" : undefined}
            />
          )}
          onKeyDown={(event) => {
            if (event.code === "Comma" && inputRef.current) {
              // @ts-ignore
              handleChange(event, [...value, event.target.value]);
              inputRef.current.value = "";
            }
          }}
        />
      </TableCell>
      <TableCell width="300">
        <FleetsDropdown {...dropdownProps} />
      </TableCell>
      <TableCell width="60" align="center">
        <IconButton disabled={disableDelete} onClick={() => onDelete(row)}>
          <Delete size={18} color="#000000" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default InviteUserRow;
