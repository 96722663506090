import { useCallback, useRef, useState } from "react";

interface ModalOptions {
  onClose?: (e: any) => void;
  onOpen?: (e: any) => void;
  /**
   * Reset state when modal is closed.
   * Default value is true.
   */
  resetOnClose?: boolean;
  /**
   * ID is used to avoid duplicate keys warning
   * when multiple modals are rendered in same component
   */
  id?: string;
}
/**
 * Helper hook to create custom modals from Modal base component.
 * @param options custom event handlers
 */
function useModal(options: ModalOptions) {
  const [isOpen, setOpen] = useState(false);
  // works well even if options are changed at runtime
  const optionsRef = useRef({ ...options, resetOnClose: options.resetOnClose ?? true });

  const handleOpen = useCallback((e: any) => {
    setOpen(true);
    optionsRef.current?.onOpen?.(e);
  }, []);

  const onClose = (e: any) => {
    setOpen(false);
    options?.onClose?.(e);
  };

  return {
    modalProps: {
      isOpen,
      onClose,
      // changing key prop will reset component state
      key: options.resetOnClose ? `${String(isOpen)}${options.id}` : undefined,
    },
    handleOpen,
    onClose,
  };
}

export { useModal };
