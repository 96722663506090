import { rem, color, Transition, rgba } from "config/variable.styles";
import styled from "styled-components";

export const TransferTableBase = styled.div`
  .MuiPaper-root {
    box-shadow: inherit;
    border-radius: 0px;
  }
  .search-form-row {
    border-bottom: ${rem(1)} solid ${color.paleGreyTwo};
    .MuiButtonBase-root {
      padding: ${rem(8)};
      color: ${color.Black};
      font-size: ${rem(18)};
      width: 34px;
      height: 34px;
    }
    .MuiInputBase-input {
      font-size: ${rem(14)};
      &::-webkit-input-placeholder {
        color: #cccccc;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: #cccccc;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #cccccc;
        opacity: 1;
      }
      &:-moz-placeholder {
        color: #cccccc;
        opacity: 1;
      }
    }
  }
  .MuiTableContainer-root {
    height: ${rem(330)};
    .MuiTable-root {
      overflow: hidden;
      .MuiTableHead-root {
        .MuiTableCell-root {
          padding: ${rem(12)} ${rem(10)};
          font-size: ${rem(14)};
          line-height: ${rem(17)};
          font-weight: bold;
          color: ${color.DarkBlack};
          border-bottom-color: ${color.paleGreyTwo};
          &.MuiTableCell-paddingCheckbox {
            padding: ${rem(12)} ${rem(10)} ${rem(12)} ${rem(17)};
          }
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          &.Mui-selected {
            background: ${color.White};
          }
          transition: ${Transition};
          cursor: pointer;
          background-color: ${color.White};
          &:hover,
          &.row-hover {
            filter: drop-shadow(0 0 ${rem(8)} ${rgba(color.Black, 0.25)});
            transform: scaleX(1.0072);
            .table-action {
              opacity: 1;
              pointer-events: inherit;
            }
            .MuiTableCell-root {
              position: relative;
              &::after {
                opacity: 1;
              }
            }
            .MuiIconButton-label {
              border-radius: 4px;
              background-color: #b3b2d1;
            }
          }
        }
        .MuiTableCell-root {
          padding: ${rem(8)} ${rem(10)};
          font-size: ${rem(14)};
          line-height: ${rem(17)};
          color: ${color.DarkBlack};
          border-bottom-color: ${color.paleGreyTwo};
          &.MuiTableCell-paddingCheckbox {
            padding: ${rem(8)} ${rem(10)} ${rem(8)} ${rem(17)};
          }
          &:first-child {
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            position: absolute;
            transition: ${Transition};
            top: 0;
            left: ${rem(-2)};
            height: 100%;
            width: ${rem(4)};
            background-color: ${color.White};
            opacity: 0;
          }
        }
      }
      .MuiTableCell-paddingCheckbox {
        padding: ${rem(12)} ${rem(10)} ${rem(12)} ${rem(17)};
        .MuiButtonBase-root {
          padding: 0px;
          color: ${color.darkBlue};
          .MuiSvgIcon-root {
            height: ${rem(19)};
            width: ${rem(19)};
          }
        }
      }
    }
  }
`;
