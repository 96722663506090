import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
};

export const setEmailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEmail } = setEmailSlice.actions;

export default setEmailSlice.reducer;
