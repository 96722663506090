const Maintenance = () => {
  return (
    <span className="icon icon-maintenance">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <g fill="none" fillRule="nonzero">
          <circle cx="16" cy="16" r="16" fill="#00C8C8"></circle>
          <path
            fill="#00A0AA"
            d="M32 16c0-.33-.01-.659-.03-.984l-6.467-6.468-5.94.233.828 
          3.31-8.703 2.686-4.778 9.026 1.687 1.687-.031.03 6.45 6.45c.325.02.654.03.984.03 8.837 0 16-7.163 16-16z"
          ></path>
          <path
            fill="#FFA300"
            d="M20.222 14.777l-.861 4.57a4.35 4.35 0 01-1.557 2.59L13.03 25.75a3.368 
          3.368 0 01-4.834-.66l7.558-5.636c.309-.23.55-.538.7-.892l1.588-3.755 2.18-.03z"
          ></path>
          <path
            fill="#FFCE00"
            d="M17.223 11.778l-4.57.861a4.35 4.35 0 00-2.59 1.557L6.25 18.97a3.368 
          3.368 0 00.66 4.834l5.636-7.558c.23-.309.538-.55.892-.7l3.755-1.588.03-2.18z"
          ></path>
          <path
            fill="#DCE1EB"
            d="M25.503 8.548l-.36 2.68a3.191 3.191 0 01-2.136 2.599l-2.725.926-1.39-1.645 1.413-1.413 
          1.69-.286c.465-.08.826-.449.895-.915l.24-1.623 1.348-1.348 1.025 1.025z"
          ></path>
          <path
            fill="#F2F6FC"
            d="M23.452 6.498l-2.68.359a3.193 3.193 0 00-2.598 2.136l-.927 2.725 1.646 1.39 
          1.412-1.413.287-1.69c.079-.465.448-.826.915-.895l1.622-.24 1.348-1.347-1.025-1.025z"
          ></path>
          <circle cx="18.893" cy="13.107" r="2.493" fill="#555A66"></circle>
          <path fill="#333940" d="M20.64 11.33l-3.526 3.524.016.016a2.493 2.493 0 103.526-3.526l-.016-.015z"></path>
          <circle cx="18.893" cy="13.107" r="1.016" fill="#707789"></circle>
          <path fill="#555A66" d="M19.595 12.374L18.16 13.81l.014.017a1.016 1.016 0 101.437-1.437l-.016-.015z"></path>
        </g>
      </svg>
    </span>
  );
};

export default Maintenance;
