import { useEffect, useRef } from "react";
import { setReadStatus } from "services/redux/reducers/Comments/comments.actions";
import { useAppDispatch } from "./reduxHooks";

/**
 * Hook to mark comments/notifications as read automatically. children nodes
 * should have id 'comment-card' and 2 data attributes - read and commentid.
 * @param deps Dependencies for intersection observer.
 * @returns Ref to parent container of the comments/notifications list.
 */
function useAutoRead(deps: unknown[] = []) {
  const observer = useRef<IntersectionObserver | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const markedComments = useRef<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!elementRef.current) return;

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { dataset } = entry.target as HTMLElement;
          if (!dataset.commentid) return;

          if (entry.isIntersecting && dataset.read === "NEW") {
            markedComments.current = [...new Set([...markedComments.current, dataset.commentid])];
          } else if (dataset.read === "READ" && markedComments.current.includes(dataset.commentid)) {
            markedComments.current = markedComments.current.filter((id) => id !== dataset.commentid);
          }
        });
      },
      { threshold: 1 }
    );
    elementRef.current?.childNodes.forEach((child) => {
      const childElement = child as HTMLDivElement;
      if (childElement.id.includes("comment-card")) {
        observer.current?.observe(childElement);
      }
    });

    return () => {
      observer.current?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    return () => {
      if (markedComments.current.length > 0) {
        dispatch(
          setReadStatus({
            requestPayload: {
              updates: markedComments.current.map((comment_id) => ({ comment_id, read_status: "READ" })),
            },
            read_status: "READ",
          })
        );
      }
    };
  }, [dispatch]);

  return elementRef;
}

export default useAutoRead;
