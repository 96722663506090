import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { ReportsFilter } from "types/FilterBar.types";

const initialState: ReportsFilter = {
  vessel: undefined,
  period: {
    value: "LAST_THIRTY_DAYS",
    range: {
      min: subDays(new Date(), 30).toISOString(),
      max: new Date().toISOString(),
    },
  },
  compare_to: "INDUSTRY_BENCHMARK",
};
const reportsFilter = createSlice({
  name: "reportsFilter",
  initialState,
  reducers: {
    setVesselFilter(state, action: PayloadAction<typeof initialState.vessel>) {
      state.vessel = action.payload;
    },
    setPeriodFilter(state, action: PayloadAction<typeof initialState.period>) {
      state.period = action.payload;
    },
    setCompareToFilter(state, action: PayloadAction<typeof initialState.compare_to>) {
      state.compare_to = action.payload;
    },
    setReportsFilter(state, action: PayloadAction<ReportsFilter>) {
      state.compare_to = action.payload.compare_to;
      state.period = action.payload.period;
      state.vessel = action.payload.vessel;
    },
  },
});

export const { setCompareToFilter, setPeriodFilter, setVesselFilter, setReportsFilter } = reportsFilter.actions;
export default reportsFilter.reducer;
