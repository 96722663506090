import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TableFilterBarState,
  FleetsFilter,
  ActivityFilter,
  SeverityFilter,
  VesselTypeFilter,
  VesselAreaFilter,
  VesselsFilter,
  ActiveFilters,
} from "types/ReduxState/TableFilterBar.types";

export type Screen = "vesselDB" | "sensor" | "activities" | "reports" | "fleet" | "vessel";
type FiltersPayloadAction<T extends object> = PayloadAction<Partial<T> | "All">;

const date = new Date();
const initialState: TableFilterBarState = {
  fleetsFilter: "All",
  vesselsFilter: undefined,
  areaNameFilter: undefined,
  compartmentNameFilter: undefined,
  cameraFilter: undefined,
  dateRange: {
    start: date.toISOString(),
    end: date.toISOString(),
    live: false,
  },
  activityFilter: [],
  severityFilter: [],
  dateFilter: date.toISOString(),
  vesselTypeFilter: {
    CARGO: false,
    TANKER: false,
    PASSENGER: false,
    OTHER: false,
  },
  vesselArea: {
    vessel: [],
    area: [],
    compartment: [],
    cameras: [],
  },
  activeFilters: {
    fleets: true,
    severity: true,
    activity: true,
    vesselType: false,
    sensorState: false,
    sensorType: false,
    location: false,
    camera: false,
    vesselArea: false,
    vessels: true,
    areaName: true,
    compartmentName: true,
  },
  enableClearFilter: false,
};

export const TableFilterBarSlice = createSlice({
  name: "FilterBar",
  initialState,
  reducers: {
    setFleetsFilter(state, action: PayloadAction<FleetsFilter>) {
      state.fleetsFilter = action.payload;
    },
    setDateRange(state, action: PayloadAction<{ start: string; end: string; live?: boolean }>) {
      state.dateRange = { ...state.dateRange, ...action.payload };
    },
    setVesselsFilter(state, action: PayloadAction<VesselsFilter>) {
      state.vesselsFilter = action.payload;
    },
    setAreaNameFilter(state, action: PayloadAction<VesselsFilter>) {
      state.areaNameFilter = action.payload;
    },
    setCameraFilter(state, action: PayloadAction<VesselsFilter>) {
      state.cameraFilter = action.payload;
    },
    setCompartmentNameFilter(state, action: PayloadAction<VesselsFilter>) {
      state.compartmentNameFilter = action.payload;
    },
    enableFilter(state, action: PayloadAction<keyof typeof initialState.activeFilters>) {
      state.activeFilters[action.payload] = true;
    },
    setSeverityFilter(state, action: PayloadAction<NonNullable<VesselsFilter>>) {
      state.severityFilter = action.payload;
    },
    clearFilterState(state, action: PayloadAction<{ activeFilters: ActiveFilters; isResetFilters: boolean }>) {
      if (action.payload.isResetFilters) {
        state.activeFilters[action.payload.activeFilters] = false;
      }
      switch (action.payload.activeFilters) {
        case "severity":
          state.severityFilter = initialState.severityFilter;
          break;
        case "activity":
          state.activityFilter = initialState.activityFilter;
          break;
        case "fleets":
          state.fleetsFilter = initialState.fleetsFilter;
          break;
        case "vessels":
          state.vesselsFilter = initialState.vesselsFilter;
          break;
        case "vesselType":
          state.vesselTypeFilter = initialState.vesselTypeFilter;
          break;
        case "areaName":
          state.areaNameFilter = initialState.areaNameFilter;
          break;
        case "compartmentName":
          state.compartmentNameFilter = initialState.compartmentNameFilter;
          break;
        case "camera":
          state.cameraFilter = initialState.cameraFilter;
          break;
        default:
          break;
      }
    },
    toggleActivityFilter(state, action: PayloadAction<ActivityFilter>) {
      if (state.activityFilter.includes(action.payload)) {
        state.activityFilter = state.activityFilter.filter((el) => el !== action.payload);
      } else {
        state.activityFilter.push(action.payload);
      }
    },
    toggleSeverityFilter(state, action: PayloadAction<SeverityFilter>) {
      if (state.severityFilter.includes(action.payload)) {
        state.severityFilter = state.severityFilter.filter((el) => el !== action.payload);
      } else {
        state.severityFilter.push(action.payload);
      }
    },
    setActivityFilter(state, action: PayloadAction<NonNullable<VesselsFilter>>) {
      state.activityFilter = action.payload;
    },
    setDateFilter(state, action: PayloadAction<string>) {
      state.dateFilter = action.payload;
    },
    setVesselTypeFilter(state, action: FiltersPayloadAction<VesselTypeFilter>) {
      if (action.payload === "All") {
        state.vesselTypeFilter = {
          CARGO: true,
          TANKER: true,
          PASSENGER: true,
          OTHER: true,
        };
      } else {
        state.vesselTypeFilter = { ...state.vesselTypeFilter, ...action.payload };
      }
    },
    setVesselAreaFilter(state, action: PayloadAction<VesselAreaFilter>) {
      state.vesselArea = action.payload;
    },
    toggleFilter(state, action: PayloadAction<keyof TableFilterBarState["activeFilters"]>) {
      state.activeFilters = { ...state.activeFilters, [action.payload]: !state.activeFilters[action.payload] };
    },
    clearFilter(state) {
      state.fleetsFilter = initialState.fleetsFilter;
      state.vesselsFilter = initialState.vesselsFilter;
      state.activityFilter = initialState.activityFilter;
      state.severityFilter = initialState.severityFilter;
      state.vesselTypeFilter = initialState.vesselTypeFilter;
      state.vesselArea = initialState.vesselArea;
      state.cameraFilter = initialState.cameraFilter;
      state.compartmentNameFilter = initialState.compartmentNameFilter;
      state.areaNameFilter = initialState.areaNameFilter;
    },
    toggleClearFilter(state, action: PayloadAction<Screen | undefined>) {
      const { fleetsFilter } = state;
      const { activity, severity, vesselType, vesselArea } = state.activeFilters;
      let allFiltersDisabled: boolean;

      switch (action.payload) {
        case "activities":
          allFiltersDisabled = !activity && !severity && !vesselArea && fleetsFilter === "All";
          break;
        case "vesselDB":
          allFiltersDisabled = !activity && !severity && !vesselArea;
          break;
        case "sensor":
          allFiltersDisabled = !vesselArea && fleetsFilter === "All";
          break;
        default:
          allFiltersDisabled = !activity && !severity && !vesselType && fleetsFilter === "All";
          break;
      }

      if (allFiltersDisabled) {
        state.enableClearFilter = false;
      } else {
        state.enableClearFilter = true;
      }
    },
  },
});

export const {
  setActivityFilter,
  setSeverityFilter,
  setFleetsFilter,
  setDateFilter,
  setVesselTypeFilter,
  setVesselAreaFilter,
  toggleFilter,
  clearFilter,
  toggleClearFilter,
  setVesselsFilter,
  clearFilterState,
  enableFilter,
  setDateRange,
  toggleActivityFilter,
  toggleSeverityFilter,
  setAreaNameFilter,
  setCompartmentNameFilter,
  setCameraFilter,
} = TableFilterBarSlice.actions;

export default TableFilterBarSlice.reducer;
