import { forwardRef, useState, ChangeEvent, useMemo } from "react";
import { IconButton, Input, MenuItem, InputBase, Checkbox, FormControlLabel } from "@material-ui/core";
import { FleetsDropdownStyle, FormControlStyle, DropdownSearchbox, AssociatedFleetLabelStyle, NoData } from "./FleetsDropdown.styles";
import { Search } from "@material-ui/icons";
import flagIcon from "../../assets/flags/in.svg";
import { FleetDropdpwnProps, CustomCheckboxProps, FleetLabelProps } from "./FleetsDropdown.types";
import Chip from "components/Chip";
import Tooltip from "components/Tooltip";
import ChevronIcon from "components/Icon/ChevronIcon";
import GreyChevronIcon from "components/Icon/GreyChevronIcon";

const AssociatedFleetLabel = (props: FleetLabelProps) => {
  const { name } = props;

  return (
    <AssociatedFleetLabelStyle className="checkbox-label">
      {/* {isPreviewEnabled && (
        <span className="icon">
          <img src={flagIconUrl} alt="icon" />
        </span>
      )} */}
      <span className="label-text">{name}</span>
    </AssociatedFleetLabelStyle>
  );
};

const CustomCheckbox = forwardRef((props: CustomCheckboxProps, ref: any) => {
  const { name, id, checked, flagIconUrl, onChange } = props;

  return (
    <FormControlLabel
      value={id}
      checked={checked}
      ref={ref}
      onChange={(e: ChangeEvent<{ value?: string }>) => onChange(e.target.value)}
      control={<Checkbox name={name} color="primary" />}
      label={<AssociatedFleetLabel name={name} flagIconUrl={flagIconUrl} />}
    />
  );
});

const FleetsDropdown = (props: FleetDropdpwnProps) => {
  const { items, onClose, onChange, disabled, label = "Fleets" } = props;
  const [text, setText] = useState("");
  const filtered = useMemo(() => items?.filter((el) => el.name.toLowerCase().includes(text.trim().toLowerCase())), [items, text]);
  const selectedItems = items.filter((e) => e.selected).slice(1);

  const handleChange = (id?: string) => {
    if (!id) return;
    onChange?.((values) => values.map((e) => (e.id === id ? { ...e, selected: !e.selected } : e)));
  };

  return (
    <FormControlStyle>
      <FleetsDropdownStyle
        labelId="associatedFleet"
        id={`associatedFleet`}
        multiple
        onClose={onClose}
        displayEmpty
        disabled={disabled}
        value={items.filter((i) => i.selected).map((e) => e.name)}
        IconComponent={disabled ? GreyChevronIcon : ChevronIcon}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected: any) =>
          selected.length === 0 ? (
            <span className="placeholder">Select{label === "none" ? "" : ` ${label}`}...</span>
          ) : (
            <>
              <Chip label={selected[0]} style={{ borderRadius: "5%" }} />
              <Tooltip
                placement="top-start"
                arrow
                title={
                  <div className="vertical">
                    {selectedItems.map(({ id, name }) => (
                      <Chip key={id} label={name} />
                    ))}
                  </div>
                }
              >
                {selected.length > 1 ? <span>+{selected.length - 1}</span> : <></>}
              </Tooltip>
            </>
          )
        }
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          transformOrigin: { vertical: "top", horizontal: "right" },
          getContentAnchorEl: null,
          className: "custom-dropdown align-left",
        }}
      >
        <DropdownSearchbox onClickCapture={(e) => e.stopPropagation()} onKeyDownCapture={(e) => e.stopPropagation()}>
          <form className="search-group">
            <IconButton aria-label="search">
              <Search />
            </IconButton>
            <InputBase
              value={text}
              placeholder="Search or Add Fleet"
              inputProps={{ "aria-label": "Search or Add Fleet" }}
              onChange={(e) => setText(e.target.value)}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </form>
        </DropdownSearchbox>
        <div className="list-auto-scroll">
          {filtered.length > 0 ? (
            filtered.map((data) => (
              <MenuItem key={data.id} value={data.id} className="width-checkbox">
                <CustomCheckbox onChange={handleChange} id={data.id} name={data.name} flagIconUrl={flagIcon} checked={data.selected} />
              </MenuItem>
            ))
          ) : (
            <NoData>No results found</NoData>
          )}
        </div>
      </FleetsDropdownStyle>
    </FormControlStyle>
  );
};

export default FleetsDropdown;
