const Attention = () => {
  return (
    <span className="icon icon-attention">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 40 40">
        <defs>
          <filter id="m7khffj1ca" colorInterpolationFilters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g>
                <g transform="translate(-933.000000, -230.000000) translate(911.000000, 200.000000) translate(21.990641, 30.000000)">
                  <circle cx="20.009" cy="20" r="20" />
                  <circle cx="20" cy="20.177" r="20" fill="#FFBC57" />
                </g>
                <g
                  filter="url(#m7khffj1ca)"
                  transform="translate(-933.000000, -230.000000) translate(911.000000, 200.000000) translate(21.990641, 30.000000)"
                >
                  <g>
                    <g fill="#000" fillRule="nonzero" transform="translate(6.675993, 5.000000) translate(0.000000, 1.939746)">
                      <path
                        d="M13.31 14.552c-.46 0-.833-.373-.833-.832V7.898c0-.46.373-.832.832-.832.46
                                 0 .832.373.832.832v5.822c0 .46-.372.832-.832.832z"
                      />
                      <path
                        d="M26.266 18.727L15.912 1.47c-1.175-1.958-4.028-1.962-5.205 
                                0L.352 18.727c-.979 1.632.2 3.726 2.11 3.726h21.695c1.91 0
                                 3.088-2.094 2.11-3.726zm-2.11 2.063H2.463c-.62 0-1-.68-.683-1.207L12.133
                                  2.326c.531-.885 1.82-.886 2.353 0L24.84 19.583c.316.527-.063 1.207-.683 1.207z"
                      />
                      <circle cx="13.309" cy="16.631" r="1.248" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default Attention;
