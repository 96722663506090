import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ReduxState } from "types/ReduxState/common.types";
import { UserPermissions } from "types/User.types";
import axios from "config/api";
import { user } from "constants/api/userProfile";

const initialState: ReduxState<UserPermissions | null> = {
  status: "idle",
  data: null,
  error: null,
};

export const fetchUserPermissions = createAsyncThunk("permissions", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<UserPermissions>(user.permissions, { disableErrorToast: true });
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response?.data?.details);
  }
});
const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserPermissions.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchUserPermissions.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchUserPermissions.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = JSON.stringify(action.payload);
    });
  },
});

export default permissionSlice.reducer;
