import { isToday as istoday, format, differenceInDays, subDays, addDays } from "date-fns";
import { UTCDate } from "utils";

/**
 * Helper function to parse startDate and endDate and return
 * common variables and functions to simplify logic.
 * @param start Start date from date picker
 * @param end End date from date picker
 */
export const parseDateRange = (start: string, end: string, isLive: boolean) => {
  const pattern = "yyyy-MM-dd'T'00:00:00";
  const patternEnd = "yyyy-MM-dd'T'23:59:59";
  const startDate = new Date(format(new Date(start), pattern));
  const endDate = new Date(format(new Date(end), patternEnd));
  const isSameDaySelected = format(startDate, pattern) === format(endDate, pattern);
  const isSameMonth = isSameDaySelected ? false : format(startDate, "yyyy-MM") === format(endDate, "yyyy-MM");
  const isToday = isSameDaySelected && istoday(startDate);
  const isEndDayToday = istoday(endDate);

  return {
    isSameDaySelected,
    isSameMonth,
    isToday,
    startDate,
    endDate,
    isEndDayToday,
    getDateRange: () => {
      if (isLive) {
        const date = UTCDate();
        const currentDayPattern = "yyyy-MM-dd'T'HH:mm:ss";
        return {
          min: format(subDays(date, 1), currentDayPattern),
          max: format(date, currentDayPattern),
        };
      }
      return {
        min: format(startDate, pattern),
        max: format(endDate, patternEnd),
      };
    },
    getText: () => {
      // If start and end date months are different, show both.
      const endDateFormatter = isSameMonth ? "dd" : "MMM dd";
      // If start and end date are equal, show only 1 date. otherwise show range.
      return `${format(startDate, "MMM dd")}${isSameDaySelected ? "" : " - " + format(endDate, endDateFormatter)}`;
    },
    getDifference: () => differenceInDays(addDays(endDate, 1), startDate),
  };
};
