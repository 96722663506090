/* eslint-disable max-lines */
import { createGlobalStyle } from "styled-components";
import { color, rem, rgba, Transition } from "./config/variable.styles";

export const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
}
  body {
    margin: 0;
    background-color: ${color.duckEggBlue};
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } 
  .container {
    max-width: ${rem(1424)};
    margin: 0 auto;
    padding: 0 ${rem(15)};
  }
  .mainWrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .page-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      max-height: calc(100% - ${rem(99)});
      height: calc(100% - ${rem(99)});
    }
    &.hasTimeline{
      min-height:calc(100vh - 140px)
    }
    &.vesselWrapper{
      min-height:calc(100vh - 100px)
    }
  }
  .page-loader {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${color.White};
  }
  .custom-dropdown {
    .MuiMenu-paper {
      padding-top: ${rem(17)};
      box-shadow: inherit;
      max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: transparent;
      -webkit-appearance: none !important;
      -moz-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      -webkit-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      -ms-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      -o-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      margin-top: 0;
      min-width: auto !important;
    }
    &.scrollbar-menu {
      .MuiMenu-paper {
        height: 190px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .list-auto-scroll {
      max-height: 206px;
      overflow: auto;
        .clear-Selected-Items {
          padding:0 16px;
          margin: 0;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          line-height: 2;
          display: block;
      }
    }
    &.vessel-area-filter-menu {
      .MuiMenu-paper {
        height: 140px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .MuiList-root {
      padding: ${rem(10)} 0;
      background-color: ${color.grayWhite};
      &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
        }
      .title {
        font-size: ${rem(14)};
        font-weight: 700;
        margin: 0;
        padding: ${rem(8)} ${rem(20)};
        text-transform: uppercase;
      }
      .MuiDivider-root {
        margin: ${rem(10)} 0;
        background-color: ${color.graywhiteTwo};
      }
      .MuiButtonBase-root {
        font-size: ${rem(14)};
        font-weight: 400;
        line-height: 1;
        padding: ${rem(8)} ${rem(20)};
        color: ${color.DarkBlack};
        &:hover, &.active {
          background-color: ${color.graywhiteTwo};
        }
        &.Mui-disabled {
          color: ${color.pinkishGrey};
          opacity: 1;
        }
        .MuiListItemIcon-root {
          min-width: auto;
          padding-right: ${rem(6)};
          font-size: ${rem(18)};
          .icon  {
            display: flex;
          }
        }
        .MuiListItemText-root {
          margin: 0;
        }
        .MuiTypography-root {
          font-size: ${rem(14)};
          font-weight: 400;
          line-height: 1;
        }
        &.width-checkbox {
          padding: 0;
          &:hover {
            .MuiFormControlLabel-root {
              .MuiButtonBase-root {
                background-color: ${color.cloudyBlueTwo};
              }
            }
          }
          .MuiFormControlLabel-root {
            margin: 0;
            display: flex;
            width: 100%;
            padding: ${rem(3)} ${rem(16)};
            .MuiButtonBase-root {
              padding: 0 0 ${rem(1)};
              transition: ${Transition};
              border-radius: ${rem(4)};
              height: ${rem(24)};
              width: ${rem(24)};
              color: ${color.darkBlue};
              .MuiSvgIcon-root {
                height: ${rem(19)};
                width: ${rem(19)};
              }
            }
            .MuiTypography-root {
              padding-left: ${rem(6)};
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    &.align-left {
      .MuiList-root {
          &::after {
          top: ${rem(-16)};
          right: 0;
          border-bottom: ${rem(16)} solid ${color.grayWhite};
          border-left: ${rem(18)} solid transparent;
        }
      }
    }
    &.align-right {
      .MuiList-root {
          &::after {
          top: ${rem(-16)};
          left: 0;
          border-bottom: ${rem(16)} solid ${color.grayWhite};
          border-right: ${rem(18)} solid transparent;
        }
      }
    }
    &.bottom-align-left {
      .MuiMenu-paper {
        padding: 0 0 ${rem(17)};
      }
      .MuiList-root {
          &::after {
          bottom: ${rem(-16)};
          left: 0;
          border-top: ${rem(16)} solid ${color.grayWhite};
          border-right: ${rem(18)} solid transparent;
        }
      }
    }
  }

  .Toastify {
    &__toast-container  {
      top: ${rem(80)};
      right: ${rem(80)};
      width: ${rem(520)};
    }
    &__toast {
      background-color: ${color.White};
      border-radius: 0;
      box-shadow: 0 0 ${rem(12)} 0 ${rgba(color.Black, 0.15)};
      padding: ${rem(21)} ${rem(22)};
      position: relative;
      align-items: center;
      display: flex;
      &--error{
        border-top: ${rem(4)} solid ${color.coral};
      }
      &--info {
        border-top: ${rem(4)} solid ${color.TurquoiseBlueThree};
      }
      &--success {
        border-top: ${rem(4)} solid ${color.aquaMarine};
      }
      &.alert-toast {
        border-top: ${rem(4)} solid ${color.coral};
      }
      &.attention-toast {
        border-top: ${rem(4)} solid ${color.paleOrange};
      }
      &.routine-toast {
        border-top: ${rem(4)} solid ${color.aquaMarine};
      }
      &-body {
        padding: 0;
        margin: 0;
        .toast-content {
          align-items: center;
          display: flex;
          &__icon {
            flex: 0 0 ${rem(40)};
            width:${rem(40)};
            max-width:${rem(40)};
            .icon  {
              font-size: ${rem(40)};
              display: flex;
            }
          }
          &__info {
            padding:0 0 0 ${rem(20)}; 
            h3 {
              font-size: ${rem(14)};
              font-weight: 900;
              line-height: ${rem(20)};
              margin: 0;
              text-transform: uppercase;
              color: ${color.DarkBlack};
            }
            p {
              font-size: ${rem(14)};
              line-height: ${rem(20)};
              margin: 0; 
              color: ${color.DarkBlack};
              strong {
                font-weight: 900;
              }
            }
          }
        }
      } 
    }
    .MuiAlert-action {
      position: absolute;
      top: ${rem(4)};
      right: ${rem(4)};
      padding: 0;
      margin: 0;
      color: ${color.Black};
      .MuiButtonBase-root {
        padding: 0;
        font-size: ${rem(16)};
        .MuiSvgIcon-root {
          font-size: ${rem(28)};
        }
      }
    }
  }
  .MuiTooltip-popper {
    .MuiTooltip-tooltip{
      margin: ${rem(2)} 0;
      background-color: ${color.offWhite};
      font-size: ${rem(14)};
      font-weight: 400;
      color: ${color.DarkBlack};
      padding: ${rem(16)} ${rem(20)};
      border-radius: ${rem(4)};
      box-shadow: 0 ${rem(2)} ${rem(4)} 0 ${rgba(color.Black, 0.26)};
      .MuiTooltip-arrow {
        color: ${color.offWhite};
      }
    }
  }
  .headingPopoverContent {
    ul {
        margin: ${rem(0)};
        padding:${rem(5)} ${rem(10)};
        list-style: none;
        li {
            padding:${rem(5)} ${rem(0)};
            font-size: ${rem(14)};
        }
    }
  }
`;
