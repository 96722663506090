import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "config/api";
import { filtersAPI } from "constants/api/filters";
import { ERROR_MESSAGE } from "constants/api/common";
import { FilterResponseType } from "types/ReduxState/FiltersState.types";
import { ReduxState } from "types/ReduxState/common.types";
import { Filter } from "hooks/useFilters";

export interface FilterBarState extends ReduxState<FilterResponseType> {}

const initialState: FilterBarState = {
  status: "idle",
  error: null,
  data: null,
};

export const fetchFilters = createAsyncThunk("fetchFilters", async (requestQuery: Filter, thunkAPI) => {
  try {
    const res = await axios.post<FilterResponseType>(filtersAPI, requestQuery);
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

const filtersOptions = createSlice({
  name: "activityByVessel",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFilters.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchFilters.rejected, (state, action) => {
      state.status = "error";
      state.error = JSON.stringify(action.payload);
      state.data = null;
      state.data = null;
    });
  },
});

export default filtersOptions.reducer;
