import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BoundingRect {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

interface VesselTooltipReferenceState {
  boundingRect: BoundingRect | null;
}

const initialState: VesselTooltipReferenceState = {
  boundingRect: null,
};

export const VesselTooltipReferenceSlice = createSlice({
  name: "VesselTooltip",
  initialState,
  reducers: {
    setTooltipReferenceElement(state, action: PayloadAction<BoundingRect | null>) {
      state.boundingRect = action.payload;
    },
  },
});

export const { setTooltipReferenceElement } = VesselTooltipReferenceSlice.actions;

export default VesselTooltipReferenceSlice.reducer;
