import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestPasswordState, RequestPasswordAPIResponse } from "../../../../types/ReduxState/RequestPasswordState.types";
import { RequestPasswordType } from "../../../../types/RequestPassword.types";
import axios from "../../../../config/api";
import { auth } from "../../../../constants/api/auth";
import { ERROR_MESSAGE } from "../../../../constants/api/common";
import { toast } from "react-toastify";
import { ErrorToast } from "components/Toastbar";

const initialState: RequestPasswordState = {
  status: "idle",
  data: null,
  error: null,
};

export const requestPassword = createAsyncThunk("/requestPassword", async (rawemail: RequestPasswordType, thunkAPI) => {
  const newemail = { email: rawemail.email.trim() };
  try {
    const res = await axios.post<RequestPasswordAPIResponse>(auth.requestPassword, newemail, { disableErrorToast: true });
    return res.data;
  } catch (error) {
    let errorMessage = ERROR_MESSAGE;
    if (error?.response?.data?.details) {
      if (typeof error.response.data.details === "string") {
        errorMessage = error.response.data.details;
      } else {
        errorMessage = JSON.stringify(error.response.data.details);
      }
    }

    toast.error(<ErrorToast>{errorMessage}</ErrorToast>);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const requestPasswordSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestPassword.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(requestPassword.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload.data;
    });
    builder.addCase(requestPassword.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
      state.data = null;
    });
  },
});
export default requestPasswordSlice.reducer;
