import { MouseEvent } from "react";
import { ChipProps as MuiChipProps } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DefaultChip, RoundedChip } from "./Chip.styles";

type ChipPropsBase = Omit<MuiChipProps, "variant">;

interface ChipProps extends Omit<ChipPropsBase, "onDelete"> {
  variant?: "default" | "rounded";
  enableDelete?: boolean;
  onDeleteChip?: (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => void;
}
const Chip = (props: ChipProps) => {
  const { variant = "default", enableDelete, onDeleteChip, ...rest } = props;
  const chipProps: ChipPropsBase = {
    clickable: true,
    onDelete: enableDelete ? (e) => e.stopPropagation() : undefined,
    onMouseDown: (e) => e.stopPropagation(),
    deleteIcon: enableDelete ? (
      <>
        <CloseIcon onMouseDown={onDeleteChip} />
      </>
    ) : undefined,
    ...rest,
  };
  const ChipComponent = variant === "default" ? DefaultChip : RoundedChip;
  return <ChipComponent {...chipProps} />;
};

export default Chip;
