/* eslint-disable max-len */
import { images } from "config/images";
import { forwardRef, MouseEvent, useState } from "react";
import { convertDuration, generatePathWithQuery, getColor, isPreviewEnabled, UTCDate } from "utils";
import { CommentsCardContainer, ReadDot, CommentAction, SeverityIndicator } from "../NotificationContent.styles";
import InlineReply from "../InlineReply/InlineReply";
import { differenceInSeconds, format } from "date-fns";
import Reply from "components/Icon/Reply";
import Reaction from "components/Icon/Reaction";
import { Menu, MenuItem } from "@material-ui/core";
import MoreAction from "components/Icon/MoreAction";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useHistory } from "react-router-dom";
import routes from "config/routes";
import { setReadStatus } from "services/redux/reducers/Comments/comments.actions";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "components/Toastbar";
import { ReadStatus } from "types/Comments.types";

interface CommentsCardProps {
  // Comment details props
  fullName: string;
  text: string;
  createdAt: string;
  imageUrl: string | null;
  activityID: string;
  commentID: string;
  readStatus: ReadStatus;
  // Activity details props
  /** activity start time */
  startTime?: string;
  /** activity end time */
  endTime?: string;
  /** activity description */
  activityTitle?: string;
  /** activity severity - Alert, Attention, Routine */
  severity?: string;
  /** vessel id of activity */
  vesselID?: string;
  onNotificationClose: () => void;
}
const CommentsCard = (props: CommentsCardProps, ref: any) => {
  const {
    fullName,
    text,
    imageUrl,
    activityID,
    commentID,
    readStatus,
    severity,
    vesselID,
    createdAt,
    activityTitle,
    startTime,
    endTime,
    onNotificationClose,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const fleetID = useAppSelector((state) => {
    let id: string | undefined;
    state.fleetInfo.data?.fleets.forEach((fleet) => {
      fleet.vessels.forEach((vessel) => {
        if (vessel.id === vesselID) {
          id = fleet.id;
        }
      });
    });
    return id;
  });

  const activityStartTime = startTime && format(new Date(startTime), "EEEE, MMMM dd, yyyy HH:mm:ss");
  const activityEndTime = endTime && format(new Date(endTime), "HH:mm:ss 'UTC'");
  const time = convertDuration(differenceInSeconds(UTCDate(), new Date(createdAt)), { short: true });
  const read = readStatus === "READ" || !readStatus;

  const handleClose = () => setAnchorEl(null);
  const handleMoreActionClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const handleCopyLink = () => {
    if (!startTime || !endTime) return;
    if ("clipboard" in navigator) {
      const path = generatePathWithQuery(
        routes.vessel.path,
        { activityID, start_dttm: startTime, end_dttm: endTime, showComment: "true" },
        { fleet: fleetID, vessel: vesselID }
      );
      navigator.clipboard
        ?.writeText(`${window.location.origin}${path}`)
        .then(() => {
          toast.success(<SuccessToast>Link copied to the clipboard.</SuccessToast>);
        })
        .catch(() => {
          toast.error(<ErrorToast>An error occurred copying the link.</ErrorToast>);
        });
    } else {
      toast.error(<ErrorToast>Clipboard is not supported in the browser.</ErrorToast>);
    }
    setAnchorEl(null);
  };

  const handleReadClick = () => {
    dispatch(
      setReadStatus({
        requestPayload: {
          updates: [
            {
              comment_id: commentID,
              read_status: read ? "KEEP_UNREAD" : "READ",
            },
          ],
        },
        commentID,
        read_status: read ? "KEEP_UNREAD" : "READ",
      })
    )
      .then(() => {
        setAnchorEl(null);
      })
      .catch((e) => console.log(e));
  };

  const handleCommentClick = () => {
    if (!fleetID || !vesselID || !activityID || !startTime || !endTime) return;
    const path = generatePathWithQuery(
      routes.vessel.path,
      { activityID, start_dttm: startTime, end_dttm: endTime, showComment: "true" },
      { fleet: fleetID, vessel: vesselID }
    );
    history.push(path, { openComment: true });
    onNotificationClose();
    // Marking comment as read when user opens the comment view.
    if (!read) handleReadClick();
  };

  return (
    <CommentsCardContainer id="comment-card" data-read={readStatus} data-commentid={commentID} ref={ref}>
      <Menu
        id="title-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        className="custom-dropdown align-left scrollbar-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleReadClick}>Mark as {read ? "unread" : "read"}</MenuItem>
        {isPreviewEnabled && <MenuItem>Turn off notifications</MenuItem>}
        <MenuItem onClick={handleCopyLink}>Copy link</MenuItem>
      </Menu>
      <ReadDot onClick={handleReadClick} read={read} />
      <div className="userThumb">
        <img src={imageUrl ?? images.placeholderProfile} onError={(e) => (e.currentTarget.src = images.placeholderProfile)} alt="user thumb" />
      </div>
      <div className="CommentsContent">
        <h3>{fullName}</h3>
        <p className="comment-text" onClick={handleCommentClick}>
          {text}
          <strong>({time} ago)</strong>
        </p>
        <div className="comment-type">
          {severity && <SeverityIndicator backgroundColor={getColor(severity)} />}
          {/* <p className="type-id">000A1</p> */}
          <div className="type-details">
            <p className="info" title={activityTitle}>
              {activityTitle}
            </p>
            <p className="activity-time" onClick={handleCommentClick}>
              {!!activityStartTime && `${activityStartTime} - `} {!!activityEndTime && `${activityEndTime}`}
            </p>
          </div>
        </div>
        {editMode ? (
          <InlineReply setEditMode={setEditMode} onClose={() => setEditMode(false)} activityID={activityID} commentID={commentID} />
        ) : (
          <div className={`comment-action ${!!anchorEl ? "visible" : ""}`}>
            <CommentAction onClick={() => setEditMode(true)}>
              <Reply />
            </CommentAction>
            {isPreviewEnabled && (
              <CommentAction>
                <Reaction />
              </CommentAction>
            )}
            <CommentAction onClick={handleMoreActionClick}>
              <MoreAction size={16} color="#333" style={{ marginTop: "5px" }} />
            </CommentAction>
          </div>
        )}
      </div>
    </CommentsCardContainer>
  );
};

export default forwardRef(CommentsCard);
