import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./User/user.slice";
import mapReducer from "./Map/Map.slice";
import VesselHeader from "./Vessels/VesselHeader.slice";
import ActivityBar from "./ActivityBar/ActivityBar.slice";
import RequestPassword from "./User/requestPassword.slice";
import ValidateToken from "./User/validateToken.slice";
import SetEmail from "./Email/setEmail.slice";
import Username from "./User/userName.slice";
import eventTimelineReducer from "./Activities/eventTimeline.slice";
import activityDetailsReducer from "./Activities/activityDetails.slice";
import fetchFleets from "./Fleets/Fleets.slice";
import activityByFleet from "./ActivityBar/ActivityByFleet.slice";
import TableFilterBarReducer from "./TableFilterBar/TableFilterBar.slice";
import VesselTooltipReferenceReducer from "./Map/VesselTooltipReference.slice";
import RefreshTimerSlice from "./RefreshTimer/RefreshTimer.slice";
import UserProfileReducer from "./User/userProfile.slice";
import setVideoStatus from "./OpenVideo/OpenVideo.slice";
import GlobalDBTooltipReferenceReducer from "./Map/GlobalDBTooltipReference.slice";
import GlobalDBClusterTooltipReferenceReducer from "./Map/GlobalDBClusterTooltipReference.slice";
import activityByVessel from "./ActivityBar/ActivityByVessel.slice";
import setVesselId from "./Vessels/VesselId.slice";
import signedUrlReducer from "./User/signedUrl.slice";
import VideoControlsReducer from "./VideoControls/VideoControls.slice";
import ghostVesselSlice from "./Map/GhostVessel.slice";
import AISSlice from "./Voyage/AIS.slice";
import vesselDBTooltip from "./Map/Tooltip.slice";
import comments from "./Comments/comments.slice";
import notifications from "./Comments/notification.slice";
import sensors from "./Sensors/Sensors.slice";
import permissions from "./User/permission.slice";
import reports from "./Reports/Reports.slice";
import reportsFilter from "./TableFilterBar/ReportsFilter.slice";
import reportStats from "./Reports/ReportStats.slice";
import bridgewatch from "./Reports/BridgeWatch.slice";
import filtersOptions from "./TableFilterBar/Filters.slice";

export default combineReducers({
  user: userReducer,
  map: mapReducer,
  vesselHeader: VesselHeader,
  activityBar: ActivityBar,
  requestPassword: RequestPassword,
  validateToken: ValidateToken,
  setEmail: SetEmail,
  username: Username,
  eventTimeline: eventTimelineReducer,
  activityDetails: activityDetailsReducer,
  fleetInfo: fetchFleets,
  activityByFleet: activityByFleet,
  tableFilterBar: TableFilterBarReducer,
  VesselTooltipReference: VesselTooltipReferenceReducer,
  refreshTimer: RefreshTimerSlice,
  userProfile: UserProfileReducer,
  openVideo: setVideoStatus,
  GlobalDBTooltipReference: GlobalDBTooltipReferenceReducer,
  GlobalDBClusterTooltipReference: GlobalDBClusterTooltipReferenceReducer,
  activityByVessel: activityByVessel,
  setVesselId: setVesselId,
  signedUrl: signedUrlReducer,
  videosPlayed: VideoControlsReducer,
  ghostVessel: ghostVesselSlice,
  ais: AISSlice,
  vesselDBTooltip: vesselDBTooltip,
  comments: comments,
  notifications: notifications,
  sensors: sensors,
  permissions: permissions,
  reports,
  reportsFilter,
  reportStats,
  bridgewatch,
  filterBar: filtersOptions,
});
