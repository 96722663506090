import { lazy, LazyExoticComponent } from "react";
import { RouteComponentProps } from "react-router-dom";

const rootPath = window.__RUNTIME_CONFIG__.REACT_APP_ROOT_PATH ? `/${window.__RUNTIME_CONFIG__.REACT_APP_ROOT_PATH}` : "";

interface Path {
  /** Route component props */
  path: string;
  component: LazyExoticComponent<(props: RouteComponentProps) => JSX.Element> | ((props: RouteComponentProps) => JSX.Element);
  exact: boolean;
  /** Is path accessible without user authentication. */
  isPrivate: boolean;
  /** Is path visible or hidden under feature flag. */
  visible: boolean;
  /** Does the screen cover full viewport width */
  fullscreen?: boolean;
}
const routes: Record<string, Path> = {
  homeDashboard: {
    path: rootPath + "/",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/Dashboard/")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
  vessels: {
    path: rootPath + "/vessels",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/VesselList")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
  vessel: {
    path: rootPath + "/vessels/:fleet/:vessel",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/VesselDashboard")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
  sensors: {
    path: rootPath + "/sensors",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/Sensors")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
  fleets: {
    path: rootPath + "/fleets",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/FleetDashboard/Fleets")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
  login: {
    path: rootPath + "/login",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/Login")),
    exact: true,
    isPrivate: false,
    visible: true,
  },
  requestPassword: {
    path: rootPath + "/request-password",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/RequestPassword")),
    exact: true,
    isPrivate: false,
    visible: true,
  },
  validateToken: {
    path: rootPath + "/validate-token",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/ConfirmVerification")),
    exact: true,
    isPrivate: false,
    visible: true,
  },
  newpassword: {
    path: rootPath + `/reset-password/:reset_token`,
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/NewPassword")),
    exact: true,
    isPrivate: false,
    visible: true,
  },
  userprofile: {
    path: rootPath + "/user-profile",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/UserProfile")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
  activitiesdashboard: {
    path: rootPath + "/activities",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/ActivitiesDashboard")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
  reports: {
    path: rootPath + "/reports",
    component: lazy<(props: RouteComponentProps) => JSX.Element>(() => import("../pages/Reports")),
    exact: true,
    isPrivate: true,
    visible: true,
  },
};

export default routes;
