import React, { Suspense, useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import routes from "./config/routes";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import Header from "../src/components/Header/Header";
import PrivateRoute from "./PrivateRoute";
import history from "./config/history";
import ToastContainer from "./components/Toastbar";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { autoRefresh } from "services/redux/reducers/RefreshTimer/RefreshTimer.slice";
import { images } from "config/images";
import { getCurrentUser } from "services/redux/reducers/User/user.slice";
import useDateRange from "hooks/useDateRange";
import smartlook from "smartlook-client";
import { fetchFleets } from "services/redux/reducers/Fleets/Fleets.slice";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0854a0",
    },
    error: {
      main: "#f05e5c",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
});

const rootPath = process.env.REACT_APP_ROOT_PATH ? `/${process.env.REACT_APP_ROOT_PATH}` : "";

function App() {
  const dispatch = useAppDispatch();
  const {
    range: { live },
  } = useDateRange();
  const eventTimeline = useAppSelector((state) => state.eventTimeline.data);
  const vesselClass = eventTimeline && eventTimeline.length ? "hasTimeline" : "vesselWrapper";
  const user = useAppSelector((state) => state.user.data);

  useEffect(() => {
    if (!user) return;

    const { id, username, email, role } = user;
    dispatch(fetchFleets())
      .unwrap()
      .then(({ fleets }) => {
        smartlook.identify(id, {
          username,
          email,
          role,
          fleets: fleets.map((e) => e.name).join(","),
        });
      });
  }, [user, dispatch]);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (live) {
      const timer = setInterval(() => {
        dispatch(autoRefresh());
      }, 300000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [live, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Suspense
          fallback={
            <div className="page-loader">
              <img src={images.pageLoader} alt="Loading..." />
            </div>
          }
        >
          <Switch>
            {rootPath && (
              <Route exact path="/">
                <Redirect to={routes.homeDashboard.path} />
              </Route>
            )}
            {Object.keys(routes).map((routeKey) => {
              const { path, exact, component: Component, isPrivate, visible, fullscreen } = routes[routeKey];
              if (!visible) return null;
              if (isPrivate) {
                return (
                  <PrivateRoute
                    key={routeKey}
                    path={path}
                    exact={exact}
                    render={(props) =>
                      fullscreen ? (
                        <>
                          <Header />
                          <Component {...props} />
                        </>
                      ) : (
                        <div className="container">
                          <div className={`mainWrapper ${props.match.path === "/vessels/:fleet/:vessel" ? `${vesselClass}` : ""}`}>
                            <Header />
                            <Component {...props} />
                          </div>
                        </div>
                      )
                    }
                  />
                );
              }
              return <Route key={routeKey} path={path} exact={exact} render={(props) => <Component {...props} />} />;
            })}
            <Route path="*" exact>
              <Redirect to={routes.homeDashboard.path} />
            </Route>
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
