import React, { useCallback, useEffect, useState } from "react";
import {
  HeaderContainer,
  HeaderProfile,
  ProfileBtn,
  HeaderNavMenu,
  HeaderAction,
  HeaderLogo,
  HeaderActionIcon,
  NotificationsPopover,
  ActionBadge,
} from "./Header.styles";
import { Link } from "react-router-dom";
import Notifications from "../Icon/Notifications";
import Search from "../Icon/Search";
import ChevronIcon from "../Icon/ChevronIcon";
import Logo from "../Icon/Logo";
import { useLocation, useHistory } from "react-router-dom";
import routes from "../../config/routes";
import Refresh from "components/Icon/Refresh";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { manualRefresh, refreshManual } from "services/redux/reducers/RefreshTimer/RefreshTimer.slice";
import { images } from "config/images";
import NotificationContent from "../NotificationContent/NotificationContent";
import { isNotDevelopedVisible, isPreviewEnabled } from "utils";
import { clearFilter, setDateRange } from "services/redux/reducers/TableFilterBar/TableFilterBar.slice";
import { fetchNotificationCount } from "services/redux/reducers/Comments/comments.actions";
import { secondsToMilliseconds } from "date-fns";
import Skeleton from "components/Loaders/Skeleton";
import ProfileMenu from "./ProfileMenu";

type HeaderCrumb = {
  label: string;
  path: string | null;
  visible: boolean;
};

const crumbs: Array<HeaderCrumb> = [
  {
    label: "Dashboard",
    path: routes.homeDashboard.path,
    visible: true,
  },
  {
    label: "Fleets",
    path: routes.fleets.path,
    visible: true,
  },
  {
    label: "Vessels",
    path: routes.vessels.path,
    visible: true,
  },
  {
    label: "Voyages",
    path: null,
    visible: isPreviewEnabled,
  },
  {
    label: "Activities",
    path: routes.activitiesdashboard.path,
    visible: true,
  },
  {
    label: "Sensors",
    path: routes.sensors.path,
    visible: true,
  },
  {
    label: "Analytics",
    path: null,
    visible: isPreviewEnabled,
  },
  {
    label: "Reports",
    path: routes.reports.path,
    visible: true,
  },
];

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { data: userInfo, status } = useAppSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationEl, setNotificationEl] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();
  const notificationCount = useAppSelector((state) => state.notifications.counts.data);

  useEffect(() => {
    dispatch(fetchNotificationCount());

    const interval = setInterval(() => {
      dispatch(fetchNotificationCount());
    }, secondsToMilliseconds(30));
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    localStorage.clear();
    history.push(routes.login.path);
    dispatch(clearFilter());
    dispatch(setDateRange({ start: new Date().toISOString(), end: new Date().toISOString() }));
  };

  const handleUserProfile = () => {
    history.push(routes.userprofile.path);
  };

  const handleRefreshClick = () => {
    dispatch(manualRefresh());
    // Increase separate counter which triggers manual refresh
    dispatch(refreshManual());
  };

  const notificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationEl(event.currentTarget);
  };

  const notificationClose = useCallback(() => {
    setNotificationEl(null);
  }, []);

  const open = Boolean(notificationEl);
  const id = open ? "notificationPopover" : undefined;

  return (
    <HeaderContainer>
      <div className="header">
        <HeaderLogo>
          <Link to="/">
            <Logo />
          </Link>
        </HeaderLogo>
        <HeaderNavMenu>
          <ul className="nav">
            {crumbs.map((crumb, index) => {
              const { label, path, visible } = crumb;
              if (visible && path) {
                return (
                  <li className="nav__item" key={index}>
                    <Link
                      className={`nav__item_link ${"/" + pathname.split("/")[1] === path ? "nav__item_link_active" : ""}`}
                      color="inherit"
                      key={index}
                      to={path}
                    >
                      {label}
                    </Link>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </HeaderNavMenu>
        <HeaderAction>
          <ActionBadge badgeContent={notificationCount?.total} color="primary">
            <HeaderActionIcon
              className={!!notificationEl ? "open" : ""}
              aria-describedby="notificationPopover"
              onClick={notificationClick}
              aria-label="notification"
            >
              <Notifications />
            </HeaderActionIcon>
          </ActionBadge>
          <NotificationsPopover
            id={id}
            open={open}
            anchorEl={notificationEl}
            onClose={notificationClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NotificationContent onNotificationClose={notificationClose} />
          </NotificationsPopover>
          <HeaderActionIcon aria-label="Filter" onClick={handleRefreshClick}>
            <Refresh />
          </HeaderActionIcon>
          {/* <HeaderActionIcon aria-label="Filter"><Filter /></HeaderActionIcon> */}
          {isNotDevelopedVisible && (
            <HeaderActionIcon aria-label="search">
              <Search />
            </HeaderActionIcon>
          )}
        </HeaderAction>
        <HeaderProfile>
          <ProfileBtn aria-controls="customized-menu" aria-haspopup="true" variant="contained" onClick={handleClick} disableRipple={true}>
            <Skeleton loading={status === "loading"} height="32px" width="162px">
              <span className="profile-thumb">
                {userInfo?.image_url ? (
                  <img src={userInfo.image_url} alt={userInfo.username ?? ""} onError={(e) => (e.currentTarget.src = images.placeholderProfile)} />
                ) : (
                  <img src={images.placeholderProfile} alt="Profile" />
                )}
              </span>
              {userInfo?.full_name ?? userInfo?.username}
            </Skeleton>
            <ChevronIcon />
          </ProfileBtn>
          <ProfileMenu anchorEl={anchorEl} onClose={handleClose} onLogout={handleLogout} onProfileClick={handleUserProfile} />
        </HeaderProfile>
      </div>
    </HeaderContainer>
  );
};

export default Header;
