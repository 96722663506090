import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserProfileState } from "types/ReduxState/UserProfileState.types";
import { UserProfileResponse, UserProfileUpdate } from "types/User.types";
import axios from "config/api";
import { user } from "constants/api/userProfile";
import { ERROR_MESSAGE } from "constants/api/common";

const initialState: UserProfileState = {
  data: null,
  status: "idle",
  error: null,
  update_status: "idle",
  update_error: null,
};

export const fetchUserProfile = createAsyncThunk("userProfile/fetch", async (userId: string, { rejectWithValue }) => {
  try {
    const res = await axios.get<UserProfileResponse>(user.userProfile(userId));
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.error ? error.response.data.error : ERROR_MESSAGE);
  }
});

export const updateUserProfile = createAsyncThunk(
  "userProfile/update",
  async ({ userId, userProfileUpdate }: { userId: string; userProfileUpdate: UserProfileUpdate }, { rejectWithValue }) => {
    try {
      const res = await axios.patch<UserProfileResponse>(user.userProfile(userId), userProfileUpdate);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error ? error.response.data.error : ERROR_MESSAGE);
    }
  }
);

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchUserProfile.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });

    builder.addCase(updateUserProfile.pending, (state, action) => {
      state.update_status = "loading";
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.update_status = "success";
      state.data = action.payload;
    });
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.update_status = "error";
      state.update_error = action.payload as string;
    });
  },
});

export default userProfileSlice.reducer;
