import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActivityByFleetState } from "types/ReduxState/ActivityBarState.types";
import axios from "config/api";
import { ERROR_MESSAGE } from "constants/api/common";
import { activity } from "constants/api/activity";
import { SummaryByFleet, SummaryRequestQuery } from "types/ActivityDetails.types";
import { format, subDays } from "date-fns";

const initialState: ActivityByFleetState = {
  status: "idle",
  data: null,
  error: null,
};

// Data for current and previous date is required to show
// up/down arrow icon for trends in fleet listing table.
export const fetchFleetActivities = createAsyncThunk("fleetActivities", async (requestQuery: SummaryRequestQuery, thunkAPI) => {
  try {
    const resCurrent = await axios.post<SummaryByFleet>(activity.summaryByFleet, requestQuery);
    const resPrevious = await axios.post<SummaryByFleet>(activity.summaryByFleet, {
      ...requestQuery,
      filters: {
        ...requestQuery.filters,
        start_dttm: {
          min: format(subDays(new Date(requestQuery.filters.start_dttm?.min!), 1), "yyyy-MM-dd'T'HH:mm:ss"),
          max: format(subDays(new Date(requestQuery.filters.start_dttm?.min!), 1), "yyyy-MM-dd'T'23:59:59"),
        },
      },
    });
    return { current: resCurrent.data, previous: resPrevious.data };
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

const activitySummarySlice = createSlice({
  name: "activityByFleet",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFleetActivities.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchFleetActivities.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchFleetActivities.rejected, (state, action) => {
      state.error = action.payload as string;
      state.status = "error";
      state.data = null;
    });
  },
});

export default activitySummarySlice.reducer;
