import { Fleet, FleetsInfoResponse, Vessel } from "types/Fleet.types";

export function vesselsCount(fleetsInfo?: Fleet[]) {
  if (!fleetsInfo) return "-";
  // Vessel map is used to remove duplicate vessels
  // Which belongs to multiple fleets.
  const vesselsMap: Record<string, number> = {};
  fleetsInfo.forEach(({ vessels }) => {
    vessels.forEach(({ id }) => {
      if (id in vesselsMap) {
        vesselsMap[id] += 1;
      } else {
        vesselsMap[id] = 1;
      }
    });
  });
  return Object.keys(vesselsMap).length;
}

/**
 * Separates vessel based on status - ENROUTE and AT_DESTINATION.
 * @param fleetInfo Fleets array from fleets info API
 */
export function vesselStatus(fleetInfo?: Fleet[]) {
  let enroute = 0,
    inport = 0;
  fleetInfo?.forEach(({ vessels }) =>
    vessels.forEach((vessel) => {
      if (vessel.voyage_status === "EN_ROUTE") {
        enroute++;
      } else if (vessel.voyage_status === "AT_DESTINATION") {
        inport++;
      }
    })
  );
  return { enroute, inport };
}

/**
 * Removes duplicate vessels from fleets info.
 * @param data fleets info API response.
 */
export function removeDuplicateVessels(data: FleetsInfoResponse | null) {
  const vessels: Record<string, number> = {};
  const fleetsResponse = data?.fleets.map((f) => {
    return {
      ...f,
      vessels: f.vessels.filter((v) => {
        if (v.id in vessels) return false;
        vessels[v.id] = 1;
        return true;
      }),
    };
  }) as FleetsInfoResponse["fleets"];
  return { ...data, fleets: fleetsResponse };
}

interface Options {
  removeDuplicates: boolean;
}
export function forEachVessels<T>(
  response: FleetsInfoResponse | null,
  callback: (fleet: Fleet, vessel: Vessel) => T | T[] | void,
  options?: Options
) {
  const transformedItems: T[] = [];
  forEachFleets(
    response,
    (fleet) => {
      fleet.vessels.forEach((vessel) => {
        const value = callback(fleet, vessel);
        if (!value) return;
        if (Array.isArray(value)) {
          transformedItems.push(...value);
        } else {
          transformedItems.push(value);
        }
      });
    },
    options
  );
  return transformedItems;
}

export function forEachFleets<T>(response: FleetsInfoResponse | null, callback: (fleet: Fleet) => T | T[] | void, options?: Options) {
  if (!response) return;
  let fleets = [...response.fleets];
  if (options?.removeDuplicates) {
    fleets = removeDuplicateVessels(response).fleets;
  }
  const transformedItems: T[] = [];
  fleets.forEach((fleet) => {
    const value = callback(fleet);
    if (!value) return;
    if (Array.isArray(value)) {
      transformedItems.push(...value);
    } else {
      transformedItems.push(value);
    }
  });
  return transformedItems;
}
