const Routine = () => {
  return (
    <span className="icon icon-routine">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 40 40">
        <defs>
          <filter id="7w4u60vwya" colorInterpolationFilters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g>
                <g transform="translate(-933.000000, -350.000000) translate(911.000000, 320.000000) translate(21.990641, 30.000000)">
                  <circle cx="20.009" cy="20" r="20" />
                  <circle cx="20" cy="20.177" r="20" fill="#3CDAAC" />
                </g>
                <g
                  filter="url(#7w4u60vwya)"
                  transform="translate(-933.000000, -350.000000) translate(911.000000, 320.000000) translate(21.990641, 30.000000)"
                >
                  <g fill="#000" fillRule="nonzero">
                    <path
                      d="M10.833 12.083v-5c0-.46.373-.833.834-.833.46 0 .833.373.833.833v5c0 
                            .46-.373.834-.833.834-.461 0-.834-.374-.834-.834zM12.917 15.417c0 .69-.56 1.25-1.25 
                            1.25s-1.25-.56-1.25-1.25.56-1.25 1.25-1.25 1.25.56 1.25 1.25z"
                      transform="translate(8.342692, 8.333333)"
                    />
                    <g>
                      <path
                        d="M1.458 11.667c0-5.63 4.58-10.209 10.209-10.209 2.5 0 4.843.904 6.672 
                                2.505l-.625.626c-.459.457-.135 1.244.515 1.244h2.43c.403 0
                                 .73-.326.73-.729v-2.43c0-.645-.783-.976-1.245-.516l-.773.774C17.268
                                  1.06 14.558 0 11.667 0 5.233 0 0 5.233 0 11.667c0 1.14.168 2.274.499 
                                  3.37.115.383.519.603.909.487.385-.116.604-.523.487-.909-.29-.96-.437-1.95-.437-2.948zM22.835 
                                  8.297c-.117-.385-.52-.606-.91-.488-.384.117-.603.523-.487.909.29.96.437 1.951.437 2.949 
                                  0 5.629-4.58 10.208-10.208 10.208-2.5 0-4.844-.904-6.673-2.504l.625-.627c.46-.456.136-1.244-.515-1.244h-2.43c-.403
                                   0-.73.327-.73.73v2.43c0 .648.789.972 1.245.515l.774-.774c2.103 1.874 4.812 2.932 7.704 2.932 6.433 0 
                                   11.666-5.233 11.666-11.666 0-1.14-.168-2.274-.498-3.37z"
                        transform="translate(8.342692, 8.333333)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default Routine;
