import { withStyles } from "@material-ui/styles";
import { Tooltip } from "@material-ui/core";
import { rem, color, rgba } from "config/variable.styles";

export const StyledTooltip = withStyles(
  {
    popper: {
      padding: 0,
      margin: `0 0 ${rem(20)}`,
      paddingLeft: rem(20),
      minWidth: rem(171),
      filter: `drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.15)})`,
      "&.MuiTooltip-popper .MuiTooltip-tooltip": {
        padding: `${rem(12)} 0`,
        backgroundColor: color.White,
        borderRadius: 0,
        fontSize: rem(14),
        fontWeight: "normal",
        margin: 0,
        "& .vertical": {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0 15px",
          "& .MuiChip-root": {
            marginBottom: "2px",
          },
        },
        "& .MuiTooltip-arrow": {
          overflow: "hidden",
          height: rem(22),
          width: rem(33),
          left: "0 !important",
          margin: `0 0 0 0`,
          bottom: rem(-22),
          "&::before": {
            content: `""`,
            position: "absolute",
            width: 0,
            height: 0,
            borderTop: `${rem(17)} solid ${color.White}`,
            borderRight: `${rem(20)} solid transparent`,
            left: 0,
            backgroundColor: "transparent",
            transform: "inherit",
          },
        },
      },
    },
  },
  { name: "flags-tooltip" }
)(Tooltip);
