import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: "",
};

export const setUserNameSlice = createSlice({
  name: "username",
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
  },
});

export const { setUserName } = setUserNameSlice.actions;

export default setUserNameSlice.reducer;
