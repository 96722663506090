import { Skeleton as Loader, SkeletonProps } from "@material-ui/lab";
import { ReactNode } from "react";

interface Props extends SkeletonProps {
  loading: boolean | undefined;
  children: ReactNode;
}
const Skeleton = ({ loading, children, ...rest }: Props) => {
  return loading ? <Loader animation="wave" height={20} width={60} {...rest} /> : <>{children}</>;
};

export default Skeleton;
