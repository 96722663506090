/*eslint max-lines: ["error", {"max": 350, "skipComments": true}]*/

import styled from "styled-components";
import { withStyles, IconButton } from "@material-ui/core";
import { rem, color, rgba, Transition } from "config/variable.styles";

export const InviteUsersContent = styled.div`
  .MuiTableContainer-root {
    overflow: visible;
    .MuiTable-root .MuiTableHead-root {
      tr th {
        background-color: transparent;
        font-size: ${rem(16)};
        color: ${color.DarkBlack};
        line-height: ${rem(20)};
        padding: 0 ${rem(18)} ${rem(15)};
        border: 0px;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover,
        &.row-hover {
          filter: drop-shadow(0 0 ${rem(8)} ${rgba(color.Black, 0.25)});
          transform: scaleX(1.0072);
          .table-action {
            opacity: 1;
            pointer-events: inherit;
          }
          .MuiTableCell-root {
            border-right-color: ${color.White};
            position: relative;
            span {
              cursor: pointer;
            }
            &.disable-control {
              background: ${color.White};
            }
            &::after {
              opacity: 1;
            }
          }
        }
        .MuiTableCell-root {
          background-color: ${color.White};
          font-size: ${rem(16)};
          color: ${color.DarkBlack};
          line-height: ${rem(20)};
          padding: 0;
          border-color: ${color.paleGreyTwo};
          border-right: 1px solid ${color.paleGreyTwo};
          .Mui-disabled {
            opacity: 0.3;
            pointer-events: none;
          }
          &.disable-control {
            background: ${color.WhiteFour};
          }
          &.autofilled {
            .custom-control {
              .MuiInputBase-root {
                color: ${color.warmGreyTwo};
              }
            }
          }
          &:last-child {
            border-right: 0px;
          }
          &:first-child {
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: ${rem(-2)};
            height: 100%;
            width: ${rem(4)};
            background-color: ${color.White};
            opacity: 0;
          }
        }
      }
    }
  }
  .custom-autocomplete {
    .MuiChip-root {
      border-radius: 6px;
      font-size: 14px;
      background-color: ${color.paleGreyThree};
      color: ${color.DarkBlack};
      padding: 3px 10px;
      height: ${rem(24)};
      .MuiChip-label {
        padding: 0 5px 0 0;
      }
      .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
        margin: 0;
        color: rgba(0, 0, 0, 0.26);
        cursor: pointer;
      }
    }
    .MuiAutocomplete-inputRoot {
      padding: ${rem(1)};
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0px;
    }
  }
  .table-foot-btn {
    margin-top: ${rem(9)};
    .MuiLink-root {
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      color: ${color.TurquoiseBlueThree};
      &.disabled {
        cursor: default;
        color: ${color.DarkBlack};
        opacity: 0.7;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`;

export const AssociatedFleetLabelStyle = styled.div`
  display: flex;
  align-items: center;
  .icon {
    flex: 0 0 ${rem(17)};
    width: ${rem(17)};
    height: ${rem(17)};
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .label-text {
    font-size: ${rem(14)};
    color: ${color.DarkBlack};
    line-height: ${rem(17)};
    padding: 0 0 0 ${rem(15)};
    flex: 1 1 auto;
  }
`;

export const DropdownSearchbox = styled.div`
  padding: 0 ${rem(16)} ${rem(13)};
  width: ${rem(410)};
  .search-group {
    display: flex;
    position: relative;
    background: ${color.White};
    padding: 0 ${rem(10)};
    .MuiButtonBase-root {
      padding: ${rem(10)};
      font-size: ${rem(17)};
      background: transparent !important;
      .MuiTouchRipple-root {
        display: none;
      }
    }
    .MuiInputBase-root {
      font-size: ${rem(14)};
      color: ${color.DarkBlack};
    }
  }
`;

export const ModalClose = withStyles(
  {
    root: {
      position: "absolute",
      top: rem(16),
      right: rem(16),
      padding: 0,
      margin: 0,
      background: "transparent",
      color: color.Black,
    },
  },
  { name: "topbar-action-btn" }
)(IconButton);

export const TableActionBtn = withStyles(
  {
    root: {
      height: rem(24),
      width: rem(40),
      padding: rem(2),
      margin: `0`,
      transition: Transition,
      "& .icon": {
        display: "flex",
        fontSize: rem(16),
      },
    },
    label: {
      padding: "0",
      fontSize: rem(24),
      color: `${color.Black}`,
    },
  },
  { name: "table-action-btn" }
)(IconButton);
