const AllActivities = () => {
  return (
    <span className="icon icon-activities">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <g fill="none" fillRule="nonzero">
          <circle cx="16" cy="16" r="16" fill="#4C2ADE"></circle>
          <path
            fill="#3315B4"
            d="M23.17 1.694a15.943 15.943 0 014.505 11.128c0 8.836-7.163 
            16-16 16a15.92 15.92 0 01-7.17-1.694A15.952 15.952 0 0016 32c8.837
            0 16-7.164 16-16 0-6.258-3.594-11.677-8.83-14.306z"
          ></path>
          <path
            fill="#FFF9DF"
            d="M20.946 5.315L9.861 16.432a.292.292 0 00.207.499h3.628c.237 
            0 .393.247.292.46l-4.132 8.726c-.108.228.184.434.362.255l11.138-11.137a.293.293
            0 00-.207-.5h-3.716a.321.321 0 01-.29-.461l4.165-8.704c.11-.228-.183-.435-.362-.255z"
          ></path>
        </g>
      </svg>
    </span>
  );
};

export default AllActivities;
