import { Button as MuiButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { rem, color, rgba } from "config/variable.styles";

export const StyledButton = withStyles(
  {
    root: {
      height: rem(50),
      minWidth: rem(112),
      backgroundImage: `linear-gradient(to right, ${color.coolBlue}, ${color.Primary} 73%)`,
      borderRadius: rem(25),
      fontSize: rem(14),
      fontWeight: 500,
      boxShadow: "inherit",
      color: color.White,
      padding: `0 ${rem(25)}`,
      "&:hover": {
        boxShadow: "inherit",
      },
      "&.Mui-disabled": {
        background: "#e1e7ed",
      },
      "&.disable_button": {
        color: rgba(color.Black, 0.26),
        PointerEvent: "none",
      },
      "& .MuiCircularProgress-colorPrimary": {
        color: color.White,
        marginLeft: rem(10),
      },
      "&.small": {
        height: rem(32),
        padding: `0 ${rem(12)}`,
        minWidth: rem(80),
      },
    },
    label: {
      textTransform: "initial",
    },
  },
  { name: "button" }
)(MuiButton);
