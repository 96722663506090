import styled from "styled-components";
import { Button, withStyles, IconButton, Popover, Badge } from "@material-ui/core";
import { rem, Transition, color, xl, rgba } from "../../config/variable.styles";

const headerProfileXL = xl(` 
  padding-left: ${rem(40)};
`);

const headerLogoXL = xl(` 
  padding-right: ${rem(40)};
`);

export const HeaderContainer = styled.div`
  padding: ${rem(34)} 0 ${rem(33)};
  .header {
    display: flex;
    align-items: center;
  }
`;

export const HeaderLogo = styled.div`
  display: block;
  cursor: pointer;
  padding-right: ${rem(80)};
  .MuiLink-root {
    display: inline-flex;
    svg {
      width: ${rem(100)};
      height: ${rem(26)};
    }
  }
  ${headerLogoXL}
`;
export const HeaderNavMenu = styled.div`
  margin-right: auto;
  .nav {
    display: flex;
    list-style: none;
    padding: ${rem(0)};
    margin: ${rem(0)};
    &__item {
      padding: 0 ${rem(30)} 0 0;
      display: inline-flex;
      &:last-child {
        padding: 0 0 0 0;
      }
      &_link {
        letter-spacing: ${rem(0.38)};
        color: ${color.WarmGrey};
        font-size: ${rem(14)};
        line-height: ${rem(17)};
        font-weight: 600;
        text-transform: uppercase;
        transition: ${Transition};
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: ${color.DarkBlack};
          text-decoration: none;
        }
        &_active {
          color: ${color.DarkBlack};
        }
      }
    }
  }
`;
export const HeaderAction = styled.div`
  margin-left: auto;
`;
export const HeaderProfile = styled.div`
  padding-left: ${rem(80)};
  ${headerProfileXL}
`;
export const ProfileBtn = withStyles(
  {
    root: {
      background: "transparent",
      borderRadius: 0,
      border: 0,
      color: `${color.BrownishGrey}`,
      height: "auto",
      fontSize: rem(13),
      padding: "0",
      paddingRight: rem(24),
      boxShadow: "inherit",
      position: "relative",
      "&:hover": {
        background: "transparent",
        boxShadow: "inherit",
      },
      "& .profile-thumb": {
        height: rem(32),
        width: rem(32),
        flex: `0 0 ${rem(32)}`,
        borderRadius: "50%",
        marginRight: rem(10),
        background: `${color.BrownishGrey}`,
        display: "block",
        overflow: "hidden",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
      "& .icon-chevron": {
        color: `${color.Black}`,
        fontSize: rem(16),
        position: "absolute",
        right: 0,
        top: "50%",
        height: rem(16),
        transform: "translate(0, -50%)",
        display: "flex",
        alignItems: "center",
      },
    },
    label: {
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  { name: "profile-btn" }
)(Button);

export const HeaderActionIcon = withStyles(
  {
    root: {
      height: rem(32),
      width: rem(32),
      padding: rem(5),
      margin: `0 ${rem(6)}`,
      "& .icon": {
        fontSize: rem(24),
        color: `${color.Black}`,
        display: "flex",
      },
      "&:hover": {
        background: color.White,
        "& .icon svg path": {
          fill: `${color.Primary}`,
        },
      },
      "&.open": {
        background: color.White,
        "& .icon svg path": {
          fill: `${color.Primary}`,
        },
      },
    },
    label: {
      padding: "0",
      justifyContent: "center",
    },
  },
  { name: "header-action" }
)(IconButton);
export const NotificationsPopover = withStyles(
  {
    root: {
      padding: rem(10),
    },
    paper: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      width: rem(600),
      borderRadius: 0,
      height: "calc(100% - 80px)",
      boxShadow: `0 0 ${rem(20)} 0 ${rgba(color.Black, 0.2)}`,
    },
  },
  { name: "notification" }
)(Popover);

export const ActionBadge = withStyles(
  {
    root: {
      margin: 0,
    },
    badge: {
      backgroundColor: color.coral,
      fontSize: rem(14),
      fontWeight: 700,
      padding: `0 ${rem(5)}`,
      "&.MuiBadge-anchorOriginTopRightRectangle": {
        right: rem(12),
        top: rem(5),
      },
    },
  },
  { name: "action-badge" }
)(Badge);
