import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "config/api";
import { activity } from "constants/api/activity";
import { ERROR_MESSAGE } from "constants/api/common";
import { ActivityRequest } from "types/ActivityQuery.types";
import { ReduxState } from "types/ReduxState/common.types";
import { ActivityDetails } from "types/ActivityDetails.types";

const initialState: ReduxState<ActivityDetails | null> = {
  status: "idle",
  data: null,
  error: null,
};

type RequestPayload = Omit<ActivityRequest, "detailed" | "filters"> & {
  filters: Omit<ActivityRequest["filters"], "activity_id"> & { activity_id: [string] };
};
export const fetchActivityDetails = createAsyncThunk("activityDetails/fetch", async (payload: RequestPayload, thunkAPI) => {
  try {
    //const res = await axios.get<EventTimelineAPIResponse>("https://run.mocky.io/v3/7a113d0f-a2c2-4eb2-ab30-ab296f13df7b");
    const res = await axios.post(activity.activityTable, { ...payload, detailed: true });
    return res.data.activities[0] as ActivityDetails;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message ? error.response.data.message : ERROR_MESSAGE);
  }
});

export const activityDetailsSlice = createSlice({
  name: "activityDetails",
  initialState,
  reducers: {
    cleanupActivityDetail(state) {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivityDetails.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchActivityDetails.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchActivityDetails.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });
  },
});

export const { cleanupActivityDetail } = activityDetailsSlice.actions;
export default activityDetailsSlice.reducer;
