import { createSlice } from "@reduxjs/toolkit";
import { ReadCountResponse, RecentCommentsResponse } from "types/Comments.types";
import { ReduxState } from "types/ReduxState/common.types";
import { fetchRecentComments, fetchNotificationCount, setReadStatus } from "./comments.actions";

type InitialState = {
  /** Get counters about the logged-in user's unread message counts */
  counts: ReduxState<ReadCountResponse | null>;
  /** Holds the state for GET endpoint for recent comments for a user */
  recentComments: ReduxState<RecentCommentsResponse | null>;
};

const initialState: InitialState = {
  counts: {
    data: null,
    status: "idle",
    error: null,
  },
  recentComments: {
    data: null,
    status: "idle",
    error: null,
  },
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearNotifications(state) {
      return {
        ...state,
        recentComments: initialState.recentComments,
      };
    },
  },
  extraReducers: (builder) => {
    /**
     * Reducers for fetchRecentComments async action
     */
    builder.addCase(fetchRecentComments.pending, (state) => {
      state.recentComments.status = state.recentComments.data?.comments && state.recentComments.data?.comments.length > 0 ? "refetching" : "loading";
    });
    builder.addCase(fetchRecentComments.fulfilled, (state, action) => {
      return {
        ...state,
        recentComments: {
          ...state.recentComments,
          data: {
            ...state.recentComments.data,
            comments: state.recentComments.data?.comments
              ? [...state.recentComments.data?.comments, ...action.payload.comments]
              : action.payload.comments,
          },
          status: "success",
        },
      };
    });
    builder.addCase(fetchRecentComments.rejected, (state, action) => {
      return {
        ...state,
        recentComments: {
          data: null,
          status: "error",
          error: JSON.stringify(action.payload),
        },
      };
    });
    /**
     * Reducers for fetchNotificationCount async action
     */
    builder.addCase(fetchNotificationCount.pending, (state) => {
      state.counts.status = "loading";
    });
    builder.addCase(fetchNotificationCount.fulfilled, (state, action) => {
      state.counts.data = action.payload;
      state.counts.status = "success";
      state.counts.error = null;
    });
    builder.addCase(fetchNotificationCount.rejected, (state, action) => {
      state.counts.status = "error";
      state.counts.data = null;
      state.counts.error = JSON.stringify(action.payload);
    });
    /**
     * Reducer for setReadCount async action
     */
    builder.addCase(setReadStatus.fulfilled, (state, action) => {
      return {
        ...state,
        recentComments: {
          ...state.recentComments,
          data: state.recentComments.data
            ? {
                ...state.recentComments.data,
                comments: state.recentComments.data.comments.map((c) => {
                  if (c.comment.id === action.payload.commentID) {
                    return { ...c, read_status: action.payload.read_status };
                  }
                  return c;
                }),
              }
            : null,
        },
      };
    });
  },
});

export const { clearNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
